import React, { useEffect, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { Notification } from '@bringg/react-components';
import { FilterBar2Values, Row } from '@bringg/react-components';
import { useHistory, useLocation } from 'react-router-dom';
import { each as _each } from 'lodash';
import { RunStatus } from '@bringg/dashboard-sdk';

import { Translate } from 'bringg-web/translation';
import { Pagination } from 'bringg-web/components';
import { openErrorNotification } from 'bringg-web/services/utils';
import { useStores } from 'bringg-web/recipes';
import RunsHeader from '../runs-header/runs-header';
import RunsTable from '../runs-table/runs-table';
import { INITIAL_PAGE } from '../runs.consts';
import RunsFilter from '../runs-filter/runs-filter';
import { RunsFilterItems, RunFiltersParams } from '../runs-filter/runs-filter.consts';
import {
	filtersAreSet,
	getIdsFromQueryParams,
	getQueryString,
	getPageFromRouteParams,
	getPageSizeFromRouteParams,
	mapPlannedRoutesToFilter,
	mapRunsToFilter,
	mapTasksToFilter,
	prepareQueryParmas,
	mapTeamsToFilter,
	mapDriversToFilter
} from '../runs-filter/runs-filter-utils';

const Runs: React.FC = () => {
	const location = useLocation();
	const history = useHistory();
	const { tasksStore, runStore, plannedRoutesStore, teamsStore, driversStore, merchantConfigurationsStore } =
		useStores();
	const [runs, setRuns] = useState([]);
	const [page, setPage] = useState(getPageFromRouteParams(location));
	const [pageSize, setPageSize] = useState(getPageSizeFromRouteParams(location));
	const [currentFilterValues, setFilterQuery] = useState<RunFiltersParams>({});
	const [selectedRows, setSelectedRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [hasNextPage, setHasNextPage] = useState(false);
	const [filterItems, setFilterItems] = useState<RunsFilterItems>({});

	const { enablePlannedRoutes } = merchantConfigurationsStore;

	const pushToHistory = (currPage?: number, size?: number, query?: RunFiltersParams) => {
		history.push({
			pathname: '/runs',
			search: `?page=${currPage || page}&limit=${size || pageSize}${getQueryString(query)}`
		});
	};

	const onQueryChange = (query: RunFiltersParams) => {
		if (!query) return;

		setFilterQuery(query);
		pushToHistory(query.page, query.items, query);
		updateRuns(query);
	};

	const onResetQuery = () => {
		setFilterQuery({});
		pushToHistory();
		updateRuns({ page, items: pageSize });
	};

	useEffect(() => {
		initByRouteParams().catch(error => {
			console.error('failed to init by route params', error);
		});
	}, []);

	const initByRouteParams = async () => {
		const params = new URLSearchParams(location.search);
		const query: RunFiltersParams = {
			page,
			items: pageSize,
			run_status: params.get('status') as RunStatus,
			task_ids: getIdsFromQueryParams(params, 'task_ids'),
			run_ids: getIdsFromQueryParams(params, 'run_ids'),
			team_ids: getIdsFromQueryParams(params, 'team_ids'),
			driver_ids: getIdsFromQueryParams(params, 'driver_ids'),
			by_title_ids: enablePlannedRoutes && getIdsFromQueryParams(params, 'by_title_ids'),
			from_date: params.get('from_date'),
			to_date: params.get('to_date')
		};

		await getFilterItems(query);

		onQueryChange(query);
	};

	const getFilterItems = async (query: RunFiltersParams) => {
		try {
			const [tasksByIds, runsByIds, runsByTitle, teamsByIds, driversByIds] = await Promise.all([
				tasksStore.loadMany(query.task_ids || []),
				runStore.loadAllByIds(query.run_ids || []),
				plannedRoutesStore.fetchBatch(query.by_title_ids || []),
				teamsStore.fetchAll(),
				driversStore.fetchAll()
			]);

			setFilterItems({
				task_ids: mapTasksToFilter(tasksByIds),
				run_ids: mapRunsToFilter(runsByIds),
				by_title_ids: mapPlannedRoutesToFilter(runsByTitle),
				team_ids: mapTeamsToFilter(teamsByIds),
				driver_ids: mapDriversToFilter(driversByIds)
			});
		} catch (error) {
			console.error('error fetching data: error:', error, ',params: ', currentFilterValues);
			openErrorNotification(error);
		}
	};

	const getFilterResults = (filterQuery: RunFiltersParams): void => {
		const query: RunFiltersParams = { page, items: pageSize };

		_each(filterQuery, (val, key) => {
			if (val) {
				switch (key) {
					case 'dates':
						query.from_date = val[0].toISOString();
						query.to_date = val[1].toISOString();
						break;

					default:
						query[key] = val;
						break;
				}
			}
		});
		if (filtersAreSet(query)) {
			query.page = INITIAL_PAGE;
			setPage(INITIAL_PAGE);
		}

		onQueryChange(query);
	};

	const joinRunIdsAndTaskRunIds = async (runIds: number[], taskIds: number[]) => {
		const tasks = await tasksStore.loadMany(taskIds);
		const tasksRunIds = tasks.map(task => task?.run_id).filter(Boolean);

		if (!tasksRunIds.length) return null;

		if (!runIds?.length) return tasksRunIds;

		const ids = runIds.reduce((prev, currentId) => {
			if (tasksRunIds.includes(currentId)) prev.push(currentId);
			return prev;
		}, []);
		return ids.length ? ids : null;
	};

	const updateRuns = async (query: RunFiltersParams): Promise<void> => {
		setLoading(true);
		try {
			if (query.task_ids?.length) {
				query.ids = await joinRunIdsAndTaskRunIds(query.run_ids, query.task_ids);
			} else {
				query.ids = query.run_ids || [];
			}

			if (!query.ids) {
				setRuns([]);
				return;
			}

			const runsResponse = await getRootEnv().dashboardSdk.sdk.runs.getAll(prepareQueryParmas(query));
			setRuns(runsResponse.runs);
			setHasNextPage(runsResponse.next_page);
		} catch (error) {
			console.error('error fetching runs: error:', error, ',params: ', currentFilterValues);
			openErrorNotification(error);
		} finally {
			setLoading(false);
		}
	};

	const resetFilter = (field?: string) => {
		if (!field) {
			onResetQuery();
			return;
		}
		if (field === 'dates') onQueryChange({ ...currentFilterValues, from_date: null, to_date: null });
		else onQueryChange({ ...currentFilterValues, [field]: null });
	};

	const updateRunsOrPushToPageOne = async () => {
		if (page !== INITIAL_PAGE) {
			onPageChange(INITIAL_PAGE, pageSize);
		} else {
			await updateRuns(currentFilterValues);
		}
	};

	const bulkClose = async () => {
		setLoading(true);

		try {
			const response = await getRootEnv().dashboardSdk.sdk.v2.runs.bulkClose(selectedRows);

			response.results.forEach(result => {
				if (result.success) {
					Notification.success(
						<span>
							<Translate text="RUNS.RUN" /> {result.id}
						</span>,
						<Translate text="RUNS.RUN_CLOSED_SUCCESSFULLY" />
					);
				} else {
					Notification.error(
						<span>
							<Translate text="RUNS.RUN" /> {result.id}
						</span>,
						<Translate text="RUNS.RUN_CLOSED_FAILED" />
					);
				}
			});
			await updateRunsOrPushToPageOne();
		} catch (error) {
			openErrorNotification(error);
		} finally {
			setLoading(false);
			setSelectedRows([]);
		}
	};

	const onPageChange = (selectedPage: number, selectedSize: number) => {
		if (selectedPage !== page) {
			setPage(selectedPage);
			onQueryChange({ ...currentFilterValues, page: selectedPage, items: selectedSize });
		}
		if (selectedSize !== pageSize) {
			setPage(1);
			setPageSize(selectedSize);
			onQueryChange({ ...currentFilterValues, page: 1, items: selectedSize });
		}
	};

	const onRowsSelect = rows => {
		setSelectedRows(rows);
	};

	const totalPages = hasNextPage ? (page + 1) * pageSize : pageSize * page;

	return (
		<div className="runs-container">
			<Row>
				<RunsFilter
					getFilterResults={query => getFilterResults(query)}
					filterItems={filterItems}
					initialValues={currentFilterValues as FilterBar2Values}
					onResetFilter={resetFilter}
					plannedRouteEnabled={enablePlannedRoutes}
				/>
			</Row>
			<Row className="pagination-container">
				<RunsHeader bulkClose={bulkClose} selectedItemsCount={selectedRows.length} />
				<Pagination onChange={onPageChange} pageSize={pageSize} currentPage={page} total={totalPages} />
			</Row>
			<RunsTable selectedRowKeys={selectedRows} loading={loading} runs={runs} onSelect={onRowsSelect} />
		</div>
	);
};

export default Runs;
