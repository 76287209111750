import React, { useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { defaultsDeep as _defaultsDeep } from 'lodash';
import type { TimelineEventPropertiesResult } from 'vis-timeline';
import { observer } from 'mobx-react';

import { usePropOnce } from './hooks/use-prop-once';
import { Timeline } from './timeline';
import type {
	ClickFunc,
	ExtendedTimelineItem,
	GanttOptions,
	GanttTimeLineItem,
	GroupColumnDefinition,
	GroupRow,
	TimelineItemValuesHistory,
	VisTimelineGroupExtended
} from './gantt-types';
import { TimelineClusterItem, TooltipEventOption } from './gantt-types';
import type { GanttPublicApi } from './gantt-public-api';
import { getGanttPublicApi } from './gantt-public-api';
import TimeLineItemTooltip, { TooltipRenderFunction } from './components/timeline-item-tooltip/timeline-item-tooltip';
import { renderHeaderComponent } from './gantt-headers-plugin';
import TimelineTools from './components/timeline-tools/timeline-tools';
import useScrollEvent from './hooks/use-scroll-event';
import { useGanttItemEvents } from './hooks/use-gantt-item-events';
import { useGanttTimelineOptions } from './hooks/use-gantt-timeline-options';
import type { GroupHeader } from './components/group-headers/group-headers';
import { convertGanttRowToVisGroup, createVisItemFromGanttItem } from './gantt-timeline-logic';

import './gantt-timeline.scss';

const TOOLTIP_HOVER_DEBOUNCE_THRESHOLD = 300;
const REMOVE_ROW_BUTTON_WIDTH = 14; // px
const DEFAULT_INITIAL_ITEMS: GanttTimeLineItem[] = [];
const DEFAULT_INITIAL_GROUPS: GroupRow[] = [];
const DEFAULT_OPTIONS: GanttOptions = {
	defaultColumnWidth: 100, // px
	showRemoveRowButton: true,
	tooltipBy: TooltipEventOption.Click,
	tooltipDelay: TOOLTIP_HOVER_DEBOUNCE_THRESHOLD
};
const TIMELINE_SCROLL_CLASS_NAME = 'vis-panel.vis-vertical-scroll';

interface Props {
	columns?: GroupColumnDefinition[];
	rows?: GroupRow[];
	items?: GanttTimeLineItem[];
	onItemDoubleClicked?: ClickFunc;
	onItemClicked?: ClickFunc;
	onRemoveRow?: (id: number) => void;
	onItemMoveEnded?: (item: GanttTimeLineItem, valuesHistory: TimelineItemValuesHistory) => Promise<boolean>;
	adjustItemBeforeMoveEnded?: (
		item: GanttTimeLineItem,
		valuesHistory: TimelineItemValuesHistory
	) => GanttTimeLineItem;
	toolTipRender?: TooltipRenderFunction;
	options?: GanttOptions;
	onDrop?: (properties: any) => void;
	onItemsChange?: () => void;
	className?: string;
	inProgressList?: Map<number, string>;
	clearSelectedItem?: () => void;
	shouldShowTooltipArrow?: boolean;
	tooltipContainerSelector?: string;
	getClusterContent?: (item: ExtendedTimelineItem, cluster: TimelineClusterItem) => string;
	getClusterClassName?: (cluster: TimelineClusterItem) => string;
	showZoomIcons?: boolean;
	timeZone?: string;
	verticalDragHandler?: () => any;
	scrollHandler?: () => any;
	hidden?: boolean;
	mouseOverHandler?: (event: TimelineEventPropertiesResult) => any;
	mouseMoveHandler?: (event: TimelineEventPropertiesResult) => any;
	isMultiselectEnabled?: boolean;
	customEvent?: (event, timeline) => void;
	isReadOnly?: boolean;
}

export const GanttTimeline = React.forwardRef<GanttPublicApi, Props>((props: Props, ref) => {
	const {
		rows = DEFAULT_INITIAL_GROUPS,
		items = DEFAULT_INITIAL_ITEMS,
		columns,
		onItemClicked,
		onItemDoubleClicked,
		onRemoveRow = () => undefined,
		onItemMoveEnded,
		adjustItemBeforeMoveEnded,
		toolTipRender,
		options: propOptions = DEFAULT_OPTIONS,
		onDrop,
		onItemsChange,
		className,
		inProgressList,
		clearSelectedItem,
		shouldShowTooltipArrow,
		tooltipContainerSelector,
		getClusterContent,
		getClusterClassName,
		showZoomIcons = true,
		timeZone,
		verticalDragHandler,
		scrollHandler,
		hidden = false,
		mouseOverHandler,
		mouseMoveHandler,
		isReadOnly
	} = props;
	const containerRef = useRef<HTMLDivElement>(null);

	const removeTooltip = () => {
		setSelectedItemElement(null);
		setSelectedItems(null);
		clearSelectedItem?.();
	};
	useScrollEvent(containerRef, TIMELINE_SCROLL_CLASS_NAME, removeTooltip);

	const options = useMemo(() => _defaultsDeep(propOptions, DEFAULT_OPTIONS), [propOptions, items]);

	const timelineHeaders = useMemo(() => {
		const headers: GroupHeader[] = (columns || []).map((column, id) => {
			return {
				id: id + 1,
				header: column.display,
				width: column.width || options.defaultColumnWidth
			};
		});

		if (options.showRemoveRowButton) {
			headers.unshift({ id: 0, header: '', width: REMOVE_ROW_BUTTON_WIDTH }); // fake header for remove buttons column
		}

		return headers;
	}, [columns, options.defaultColumnWidth, options.showRemoveRowButton]);

	const createVisGroup = (group: GroupRow): VisTimelineGroupExtended => {
		return convertGanttRowToVisGroup(
			group,
			() => onRemoveRow(group.rowId),
			options.showRemoveRowButton ? REMOVE_ROW_BUTTON_WIDTH : undefined,
			inProgressList?.has(group.rowId)
		);
	};
	const apiRef = useRef<Timeline>(null);

	useImperativeHandle(ref, () => getGanttPublicApi(apiRef.current as Timeline), [apiRef]);

	const timelineGroups = useMemo(
		(): VisTimelineGroupExtended[] => rows.map(createVisGroup),
		[rows, inProgressList?.values()]
	);

	const timelineItems = useMemo(() => items.map(item => createVisItemFromGanttItem(item)), [items]);

	const preventUnnecessaryClick = useRef(false);

	const [isVisRenderCompleted, setVisRenderCompleted] = useState(false);

	useLayoutEffect(() => {
		if (!isVisRenderCompleted) {
			return;
		}
		renderHeaderComponent(timelineHeaders, containerRef);
	}, [isVisRenderCompleted, timelineHeaders]);

	const { extendTimelineOptions } = useGanttTimelineOptions({
		apiRef,
		options,
		timelineHeaders,
		preventUnnecessaryClick,
		setVisRenderCompleted,
		onItemMoveEnded,
		adjustItemBeforeMoveEnded,
		removeTooltip,
		rows,
		inProgressList,
		getClusterContent,
		getClusterClassName,
		isReadOnly: !!isReadOnly
	});

	usePropOnce(
		onItemMoveEnded,
		'`onItemMoveEnded` prop changed. You probably make something wrong. Now the timeline recreate its Options object.'
	);
	usePropOnce(
		adjustItemBeforeMoveEnded,
		'`adjustItemBeforeMoveEnded` prop changed. You probably make something wrong. Now the timeline recreate its Options object.'
	);

	const [selectedItems, setSelectedItems] = useState<GanttTimeLineItem[] | null>(null);
	const [selectedItemElement, setSelectedItemElement] = useState<HTMLElement | null>(null);

	const { onGanttItemClicked, onGanttItemDoubleClicked, hoverHandlers } = useGanttItemEvents({
		options,
		items,
		selectedItems: selectedItems || [],
		toolTipRender,
		preventUnnecessaryClick,
		onItemClicked,
		onItemDoubleClicked,
		setSelectedItemElement,
		setSelectedItems,
		containerRef
	});

	const onGanttRangeChange = useCallback(() => {
		if (selectedItems?.length) {
			removeTooltip();
		}
	}, [selectedItems]);

	const zoomIn = () => {
		apiRef.current?.timeline.zoomIn(0.5);
	};

	const zoomOut = () => {
		apiRef.current?.timeline.zoomOut(0.5);
	};

	const loadingGroupsIds = useMemo(() => {
		return inProgressList ? [...new Set(inProgressList.keys())] : null;
	}, [inProgressList?.values()]);

	return (
		<div className={classNames(className, 'timeline-drop-container')}>
			<div className="timeline-container" ref={containerRef}>
				<Timeline
					hidden={hidden}
					rangechangeHandler={onGanttRangeChange}
					clickHandler={onGanttItemClicked}
					doubleClickHandler={onGanttItemDoubleClicked}
					dropHandler={onDrop}
					onItemsChange={onItemsChange}
					mouseOverHandler={mouseOverHandler}
					mouseMoveHandler={mouseMoveHandler}
					options={extendTimelineOptions}
					groups={timelineGroups}
					items={timelineItems}
					ref={apiRef}
					loadingGroups={loadingGroupsIds}
					timeZone={timeZone}
					verticalDragHandler={verticalDragHandler}
					scrollHandler={scrollHandler}
					isMultiselectEnabled={props.isMultiselectEnabled}
					customEvent={props.customEvent}
					{...hoverHandlers}
				/>

				{showZoomIcons && <TimelineTools onZoomIn={zoomIn} onZoomOut={zoomOut} />}

				<TimeLineItemTooltip
					tooltipRender={toolTipRender}
					targetElement={selectedItemElement}
					ganttItems={selectedItems}
					removeTooltip={removeTooltip}
					shouldShowTooltipArrow={!!shouldShowTooltipArrow}
					tooltipContainerSelector={tooltipContainerSelector}
				/>
			</div>
		</div>
	);
});

export default observer(GanttTimeline);
