import { ServiceArea, TeamServiceArea } from '@bringg/types';
import { action, observable, computed, toJS, makeObservable } from 'mobx';
import { isEqual } from 'lodash';
import moment from 'moment';

import ServiceAreasStore from 'bringg-web/stores/service-areas-store/service-areas-store';

export enum AREA_TYPE {
	ZIPCODES = 'SERVICE_AREA.ZIPCODES',
	COORDINATES = 'SERVICE_AREA.COORDINATES'
}

export enum FILTERS {
	BY_AREA_TYPE = 'byAreaType',
	BY_TEAMS = 'byTeams',
	BY_TEXT = 'byText'
}

export type FilterObj = {
	[FILTERS.BY_AREA_TYPE]: string;
	[FILTERS.BY_TEAMS]: number[] | null;
	[FILTERS.BY_TEXT]: string;
};

export enum USED_IN {
	DELIVERY_TERMS = 'SERVICE_AREA.DELIVERY_TERMS',
	AUTOMATION = 'SERVICE_AREA.AUTOMATION',
	COORDINATES = 'SERVICE_AREA.RATE_CARDS'
}

export type DecoratedServiceArea = Partial<
	ServiceArea & {
		assignedTeams: Partial<TeamServiceArea>[];
		center: google.maps.LatLng;
		areaType: AREA_TYPE;
		path: google.maps.LatLng[];
		color: string;
	}
>;

const SERVICE_AREA_COLORS = ['#8e44ad', '#2980b9', '#27ae60', '#16a085', '#f39c12', '#d35400', '#c0392b'];
const SECONDARY_COLOR = '#7f8c8d';
const PADDING = 10;

function getColorById(id = 0) {
	return SERVICE_AREA_COLORS[id % SERVICE_AREA_COLORS.length];
}

export const NO_TEAM_OPTION_VALUE = -9999;

export default class ServiceAreaViewStore {
	serviceAreasStore: ServiceAreasStore;

	map: google.maps.Map = null;
	focusedServiceArea: DecoratedServiceArea = null;
	filters: Partial<FilterObj> = {
		byAreaType: null,
		byTeams: [],
		byText: ''
	};
	latLngFF = false;

	constructor() {
		makeObservable(this, {
			serviceAreasStore: observable,
			map: observable.ref,
			focusedServiceArea: observable,
			focusServiceArea: action,
			withoutNewRecord: computed,
			allByName: computed,
			decorated: computed,
			bounds: computed,
			filters: observable.ref,
			filterBy: action,
			filtered: computed,
			init: action,
			save: action
		});
	}

	get filtered() {
		const { byText, byAreaType, byTeams } = this.filters;
		let filtered = this.decorated;

		// during creation id == null ignore filtering
		if (byText) {
			filtered = filtered.filter(serviceArea => {
				const hasName = () => serviceArea.name && serviceArea.name.toLowerCase().includes(byText.toLowerCase());
				const hasId = () =>
					serviceArea.id && serviceArea.id.toString().toLowerCase().includes(byText.toLowerCase());
				const hasExternalId = () =>
					serviceArea.external_id &&
					serviceArea.external_id.toString().toLowerCase().includes(byText.toLowerCase());
				return !serviceArea.id || hasName() || hasId() || hasExternalId();
			});
		}

		if (byAreaType) {
			filtered = filtered.filter(serviceArea => !serviceArea.id || serviceArea.areaType === byAreaType);
		}

		if (byTeams?.length) {
			filtered = filtered.filter(serviceArea => {
				return (
					!serviceArea.id ||
					(serviceArea.team_ids || []).some(teamId => byTeams.includes(teamId)) ||
					(!serviceArea.assignedTeams?.length && byTeams.includes(NO_TEAM_OPTION_VALUE))
				);
			});
		}

		return filtered;
	}

	get decorated(): DecoratedServiceArea[] {
		if (!this.serviceAreasStore) {
			return [];
		}

		return this.getAll().map(serviceArea => {
			const path = serviceArea.polygon?.map(coords => {
				if (this.latLngFF) {
					return new google.maps.LatLng(coords[0], coords[1]);
				}

				return new google.maps.LatLng(coords[1], coords[0]);
			});
			const center = path
				? path.reduce((bounds, position) => bounds.extend(position), new google.maps.LatLngBounds()).getCenter()
				: null;

			return {
				...serviceArea,
				assignedTeams: toJS(this.serviceAreasStore.teamsServiceAreas.get(serviceArea.id)) || [],
				center,
				areaType: serviceArea.polygon?.length ? AREA_TYPE.COORDINATES : AREA_TYPE.ZIPCODES,
				path,
				color: this.focusedServiceArea?.polygon
					? this.focusedServiceArea.id === serviceArea.id
						? getColorById(serviceArea.id)
						: SECONDARY_COLOR
					: getColorById(serviceArea.id)
			};
		});
	}

	getAll() {
		if (this.focusedServiceArea?.id) {
			return this.serviceAreasStore.getAll.map(sa => {
				if (sa.id === this.focusedServiceArea.id) {
					return this.focusedServiceArea;
				}

				return sa;
			});
		} else if (this.focusedServiceArea) {
			return [this.focusedServiceArea, ...this.serviceAreasStore.getAll];
		}

		return this.serviceAreasStore.getAll;
	}

	get allByName() {
		return _.keyBy(this.getAll(), 'name');
	}

	get withoutNewRecord() {
		return this.filtered.filter(sa => sa.id);
	}

	get bounds() {
		if (!this.filtered.length) {
			return null;
		}

		const bounds = new google.maps.LatLngBounds();

		const showFocused = Boolean(this.focusedServiceArea?.polygon);

		this.filtered
			.filter(sa => sa.path)
			.forEach(sa => {
				if (!showFocused || (showFocused && sa.id === this.focusedServiceArea.id)) {
					sa.path.forEach(coordinates => bounds.extend(coordinates));
				}
			});

		return bounds;
	}

	focusServiceArea(serviceArea: DecoratedServiceArea) {
		this.focusedServiceArea = serviceArea;
	}

	onMapLoad(map) {
		this.map = map;
	}

	centerMap() {
		if (!this.map) {
			return;
		}

		this.map.fitBounds(this.bounds, PADDING);
	}

	filterBy(filterObj: Partial<FilterObj>) {
		this.filters = filterObj;
	}

	async save(serviceArea: Partial<ServiceArea>, assignedTeams: Partial<TeamServiceArea>[], { teamId = null } = {}) {
		let result;
		let teamsServiceAreas = assignedTeams;

		if (serviceArea.id) {
			result = await this.serviceAreasStore.update(serviceArea);
		} else {
			if (teamId) {
				teamsServiceAreas = [
					{
						team_id: teamId,
						priority: 0,
						effective_start_time: moment().startOf('day').toISOString()
					}
				];
			}

			result = await this.serviceAreasStore.create(serviceArea);
		}

		const anyChanges = !isEqual(this.serviceAreasStore.teamsServiceAreas.get(result.id), teamsServiceAreas);

		if (anyChanges) {
			await this.serviceAreasStore.updateAllTeamsServiceAreas(result.id, teamsServiceAreas);
		}
	}

	init(serviceAreasStore: ServiceAreasStore, latLngFF = false) {
		if (!this.serviceAreasStore) {
			this.serviceAreasStore = serviceAreasStore;
		}
		this.latLngFF = latLngFF;

		void this.serviceAreasStore.fetchAll();
		void this.serviceAreasStore.fetchTeamsServiceAreas();
	}
}
