import { useCallback, useMemo } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FilterBar2, FilterBar2ListItem, getDefaultFilterBarTranslations } from '@bringg/react-components';
import { isNil } from 'lodash';

import { useStores } from 'bringg-web/recipes';
import {
	AREA_TYPE,
	FILTERS,
	NO_TEAM_OPTION_VALUE,
	USED_IN
} from 'bringg-web/stores/service-areas-view/service-areas-view-store';
import { useTeamsTreeData } from 'bringg-web/hooks';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';

export const ServiceAreaFilterBar = observer(({ teamId = null }: { teamId?: number }) => {
	const { t } = useTranslation();
	const { serviceAreaViewStore, teamsStore } = useStores();
	const filterBarTranslations = useMemo(() => getDefaultFilterBarTranslations(t), [t]);
	const teamOptions = useTeamsTreeData(teamsStore.all);
	const newDispatcherSideMenuItemsFF = useHasFeatureFlag('dispatcher_new_side_menu_items');

	const NO_TEAM_OPTION = useMemo(
		() => ({
			label: t('SERVICE_AREA.NO_TEAM_OPTION'),
			value: NO_TEAM_OPTION_VALUE
		}),
		[t]
	);

	const getTeamsFilterInput = () => {
		if (!newDispatcherSideMenuItemsFF && (teamId || !teamOptions.length)) {
			return null;
		}

		const input = {
			type: FilterBar2.TYPES.TREE_SELECT,
			multiselect: true,
			applyFilterOnValueChange: true,
			name: FILTERS.BY_TEAMS,
			selectAllTagText: filterBarTranslations.all,
			title: filterBarTranslations.subTeams,
			values: []
		};

		input.values = newDispatcherSideMenuItemsFF ? [NO_TEAM_OPTION].concat(teamOptions) : teamOptions;

		return input;
	};

	const filterBarFilters = useMemo<FilterBar2ListItem[]>(() => {
		return [
			{
				type: FilterBar2.TYPES.SEARCH,
				name: FILTERS.BY_TEXT,
				placeholder: filterBarTranslations.search
			},
			{
				type: FilterBar2.TYPES.SELECT,
				applyFilterOnValueChange: true,
				name: FILTERS.BY_AREA_TYPE,
				selectAllTagText: filterBarTranslations.all,
				title: t('SERVICE_AREA.AREA_TYPE'),
				values: Object.values(AREA_TYPE).map((areaType, index) => ({ value: index, label: t(areaType) }))
			},
			getTeamsFilterInput(),
			{
				type: FilterBar2.TYPES.SELECT,
				applyFilterOnValueChange: true,
				name: FILTERS.BY_AREA_TYPE,
				selectAllTagText: filterBarTranslations.all,
				title: t('SERVICE_AREA.USED_IN'),
				values: Object.values(USED_IN).map(usedIn => ({ label: t(usedIn), value: usedIn })),
				disabled: true
			}
		].filter(Boolean) as FilterBar2ListItem[];
	}, [filterBarTranslations, teamOptions, t, teamId]);

	const onFilter = useCallback(
		filterObject => {
			if (!isNil(filterObject[FILTERS.BY_AREA_TYPE])) {
				filterObject[FILTERS.BY_AREA_TYPE] = Object.values(AREA_TYPE)[filterObject[FILTERS.BY_AREA_TYPE]];
			}

			if (teamId) {
				filterObject[FILTERS.BY_TEAMS] = [teamId];
			}

			if (filterObject[FILTERS.BY_TEAMS]) {
				filterObject[FILTERS.BY_TEAMS] = filterObject[FILTERS.BY_TEAMS].reduce((acc, id) => {
					acc.push(id);

					const parent = teamOptions.find(team => team.value === id);
					if (parent) {
						acc.push(...parent.children.map(child => child.value));
					}

					return acc;
				}, []);
			}

			serviceAreaViewStore.filterBy(filterObject);
		},
		[teamId, serviceAreaViewStore, teamOptions]
	);

	return (
		<FilterBar2
			className="service-area-filter-bar"
			list={filterBarFilters}
			onFilter={onFilter}
			translations={filterBarTranslations}
		/>
	);
});
