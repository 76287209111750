import { useMemo } from 'react';

import { TemplateUpdateRequest, TemplateContentType, TemplateTypeEnum } from '@bringg/types';
import { useTranslation } from 'react-i18next';

import { useStores } from 'bringg-web/recipes';
import useTemplatesStore from '../store/helpers/use-templates-store';
import { templateLanguagesFieldName } from '../consts';

export const CONTENT_INITIAL_VALUE =
	'<html>\n<head>\n<style>\n<!-- Put your CSS here -->\n</style>\n</head>\n<body>\n<p>Put your content here</p>\n</body>\n</html>';

export interface UseInitialValues {
	initialValues: TemplateUpdateRequest;
}

export const useInitialValues = () => {
	const { merchantCustomerNotificationsStore } = useStores();
	const { t } = useTranslation();

	const {
		templatesStore: { getMappedToServer }
	} = useTemplatesStore();

	const initialTypesMap = useMemo(() => {
		return {
			[TemplateTypeEnum.customer_notification_email]: {
				template_type: TemplateTypeEnum.customer_notification_email,
				name: '',
				// content_type hardcoded until business logic won't be resolved
				content_type: TemplateContentType['text/x-handlebars-template'],
				[templateLanguagesFieldName]: [
					{
						subject: '',
						content: CONTENT_INITIAL_VALUE,
						language_code: merchantCustomerNotificationsStore.defaultLanguageCode
					}
				]
			},
			[TemplateTypeEnum.print_order]: {
				template_type: TemplateTypeEnum.print_order,
				name: t('PRINT_ORDER_TEMPLATE_NAME'),
				content_type: TemplateContentType['text/x-handlebars-template'],
				[templateLanguagesFieldName]: [
					{
						subject: 'print_order',
						content: CONTENT_INITIAL_VALUE,
						language_code: merchantCustomerNotificationsStore.defaultLanguageCode
					}
				]
			}
		};
	}, [t, merchantCustomerNotificationsStore.defaultLanguageCode]);

	const getInitialValues = ({ templateId = null, type = TemplateTypeEnum.customer_notification_email }) => {
		if (templateId) {
			return getMappedToServer(templateId);
		} else {
			return initialTypesMap[type];
		}
	};

	return { getInitialValues };
};
