import React from 'react';

import { TypographyTitle, InputNumber, Tooltip, FormItem } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';

import { MIN_NUMBER_VALUE } from './constants';

const SPEED_FACTOR_MAX_PERCENT_VALUE = 300;

const RoadRestrictionsSection = () => {
	const { t } = useTranslation();

	return (
		<div className="vehicle-profiles-form--section-wrapper">
			<div>
				<TypographyTitle level={4} className="vehicle-profiles-form--section-icon">
					<BringgIcon iconName={BringgFontIcons.Truck} />
				</TypographyTitle>
			</div>
			<div className="vehicle-profiles-form--section-content">
				<TypographyTitle level={4} className="vehicle-profiles-form--section-title">
					{t('VEHICLE_PROFILES.FORM.ROAD_RESTRICTIONS')}
				</TypographyTitle>
				<div className="vehicle-profiles-form--section-sub-title">
					{t('VEHICLE_PROFILES.FORM.ROAD_RESTRICTIONS_DESCRIPTION')}
				</div>

				<div className="vehicle-profiles-form--input-group">
					<div className="vehicle-profiles-form--label-text">
						{t('VEHICLE_PROFILES.FORM.SPEED_FACTOR')}
						&nbsp;
						<Tooltip
							overlayClassName="attribute-input-tooltip"
							placement="right"
							title={t('VEHICLE_PROFILES.FORM.HELP.SPEED_FACTOR')}
						>
							<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
						</Tooltip>
					</div>
					<FormItem
						name="speed_factor"
						rules={[
							{
								type: 'number',
								min: MIN_NUMBER_VALUE,
								max: SPEED_FACTOR_MAX_PERCENT_VALUE,
								message: `${t(
									'OPTIMIZATION_CONFIGURATIONS.NUMBER_NOT_IN_RANGE'
								)}: ${MIN_NUMBER_VALUE}% - ${SPEED_FACTOR_MAX_PERCENT_VALUE}%`
							}
						]}
						className="vehicle-profiles-form-item"
					>
						<InputNumber
							placeholder={t('VEHICLE_PROFILES.FORM.SPEED_FACTOR_PLACEHOLDER')}
							className="vehicle-profiles-form--input"
							data-test-id="speed_factor"
						/>
					</FormItem>
				</div>

				<div className="vehicle-profiles-form--input-group">
					<div className="vehicle-profiles-form--label-text">
						{t('VEHICLE_PROFILES.FORM.MAX_ROUTE_DISTANCE')}
						&nbsp;
						<Tooltip
							overlayClassName="attribute-input-tooltip"
							placement="right"
							title={t('VEHICLE_PROFILES.FORM.HELP.MAX_ROUTE_DISTANCE')}
						>
							<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
						</Tooltip>
					</div>
					<FormItem
						name="max_route_distance_km"
						rules={[
							{ type: 'number', min: MIN_NUMBER_VALUE, message: t('VEHICLE_PROFILES.ERROR.NUMBER_INPUT') }
						]}
						className="vehicle-profiles-form-item"
					>
						<InputNumber
							placeholder={t('VEHICLE_PROFILES.FORM.MAX_ROUTE_DISTANCE_PLACEHOLDER')}
							className="vehicle-profiles-form--input"
							data-test-id="max_route_distance_km"
						/>
					</FormItem>
				</div>
			</div>
		</div>
	);
};

export default RoadRestrictionsSection;
