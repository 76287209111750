import { useMemo } from 'react';

type OptionType<T> = {
	value: T;
	label: string;
	children?: OptionType<T>[];
};

export type UseAddMissingOptionsProps<ValueType> = {
	options: OptionType<ValueType>[];
	value: ValueType[];
	entityName?: string;
};

export const useAddMissingOptions = <ValueType = number>({
	options,
	value = [],
	entityName = 'Entity'
}: UseAddMissingOptionsProps<ValueType>) => {
	const valuesSet = useMemo<Set<ValueType>>(() => {
		const extractValues = (acc: ValueType[], { value, children }: OptionType<ValueType>) => {
			acc.push(value);
			if (children) {
				children.forEach(child => extractValues(acc, child));
			}
			return acc;
		};

		return new Set(options.reduce(extractValues, []));
	}, [options]);
	const missingOptionsByValue = useMemo(() => {
		return value
			.filter(v => !valuesSet.has(v))
			.map(value => ({
				value,
				label: `Removed ${entityName} ${value}`
			}));
	}, [value, valuesSet]);

	return useMemo(() => missingOptionsByValue.concat(options), [missingOptionsByValue, options]);
};
