import { action, makeObservable, observable } from 'mobx';
import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { PlannedRoute } from '@bringg/types';
import { flatten } from 'lodash';

import BaseDomainStore from '../core/base-domain-store';

const MAX_ITEMS_PER_GET_REQUEST = 350;

function splitIntoChunks<T>(items: T[], chunkSize: number): T[][] {
	if (!items?.length) return [];
	const chunksCount = Math.ceil(items.length / chunkSize);
	const chunks = [];

	for (let i = 0; i < chunksCount; i += 1) {
		const isLastChunk = i + 1 === chunksCount;
		chunks.push(items.slice(i * chunkSize, isLastChunk ? items.length : (i + 1) * chunkSize));
	}
	return chunks;
}

class PlannedRoutesStore extends BaseDomainStore<PlannedRoute> {
	plannedRoutesByTeamId: Map<number, PlannedRoute[]> = new Map();

	constructor() {
		super();

		makeObservable(this, {
			plannedRoutesByTeamId: observable.shallow,
			setPlannedRoutesByTeamId: action
		});
	}

	setPlannedRoutesByTeamId = (teamId: number, plannedRoutes: PlannedRoute[]) => {
		this.plannedRoutesByTeamId.set(teamId, plannedRoutes);
	};

	fetchBatch = async (
		ids: number[],
		force?: boolean,
		options?: { signal?: AbortSignal }
	): Promise<PlannedRoute[]> => {
		if (!ids.length) return [];

		const idsToFetch = force ? ids : ids.filter(id => !this.items.has(id));

		if (idsToFetch.length) {
			const response = await getRootEnv().dashboardSdk.sdk.plannedRoutes.getBatch(idsToFetch, options);
			this.setBatch(response);
		}

		return this.getBatch(ids);
	};

	getBatch = (ids: number[]) => {
		return ids.map(id => this.items.get(id));
	};

	fetchAllByTeam = async (teamId: number): Promise<PlannedRoute[]> => {
		if (this.plannedRoutesByTeamId.has(teamId)) {
			return this.plannedRoutesByTeamId.get(teamId);
		}
		const response = await getRootEnv().dashboardSdk.sdk.plannedRoutes.getAllByTeam(teamId);
		this.setBatch(response);
		this.setPlannedRoutesByTeamId(teamId, response);
		return response;
	};

	fetchAllByTeams = async (teamIds: number[]): Promise<PlannedRoute[]> => {
		const { sdk } = getRootEnv().dashboardSdk;
		const unFetchedTemIds = teamIds.filter(teamId => !this.plannedRoutesByTeamId.has(teamId));
		if (!unFetchedTemIds.length) {
			return [];
		}

		let response;
		if (unFetchedTemIds.length <= MAX_ITEMS_PER_GET_REQUEST) {
			response = await sdk.plannedRoutes.getBatchByTeamIds(unFetchedTemIds);
		} else {
			response = flatten(
				await Promise.all(
					// eslint-disable-next-line @typescript-eslint/require-await
					splitIntoChunks(unFetchedTemIds, MAX_ITEMS_PER_GET_REQUEST).map(async chunk =>
						sdk.plannedRoutes.getBatchByTeamIds(chunk)
					)
				)
			);
		}

		const responseByTeamIdMap = new Map();
		teamIds.forEach(teamId => {
			responseByTeamIdMap.set(teamId, []);
		});

		response.forEach(plannedRoute => {
			const teamId = plannedRoute.team_id;
			responseByTeamIdMap.get(teamId).push(plannedRoute);
		});

		this.plannedRoutesByTeamId = new Map([...this.plannedRoutesByTeamId, ...responseByTeamIdMap]);
		this.setBatch([...this.items, ...response]);

		return response;
	};

	getAllByTeam = (teamId: number): PlannedRoute[] => {
		return this.plannedRoutesByTeamId.get(teamId) || [];
	};
}

export default PlannedRoutesStore;
