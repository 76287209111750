import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@bringg/react-components';

import { AddTemplateButton, TemplatesTable } from './components';
import useTemplatesStore from './store/helpers/use-templates-store';
import { TemplateDetails } from './components/tempalate-details';
import { TemplatesRoot } from './store/data-provider/templates-root';
import { StoreProvider } from './store/helpers/store-context';

import styles from './templates-manager.module.scss';

export const TemplatesManager = observer(() => {
	const { t } = useTranslation();

	const [detailsOpened, setDetailsOpened] = useState(false);
	const [selectedTemplateId, setSelectedTemplateId] = useState<number>(null);

	const {
		templatesStore: { fetchAll, notificationTemplates: allTemplates, isLoading }
	} = useTemplatesStore();

	useEffect(() => {
		fetchAll();
	}, []);

	useEffect(() => {
		if (!detailsOpened) {
			setSelectedTemplateId(null);
		}
	}, [detailsOpened]);

	const handleAddTemplate = useCallback(() => {
		setDetailsOpened(true);
	}, []);

	const handleCloseTemplateDetails = useCallback(() => {
		setDetailsOpened(false);
	}, []);

	const handleEditTemplate = useCallback((id: number) => {
		setSelectedTemplateId(id);
		setDetailsOpened(true);
	}, []);

	const componentContainerRef = useRef<HTMLDivElement>(null);

	return (
		<div className={styles.templatesManager} ref={componentContainerRef}>
			{isLoading ? (
				<Spinner />
			) : allTemplates.length ? (
				<TemplatesTable
					componentContainerRef={componentContainerRef}
					onAddTemplate={handleAddTemplate}
					onEditTemplate={handleEditTemplate}
				/>
			) : (
				<div className={styles.emptyState}>
					<img src="images/empty-table-icon.svg" />
					<span className={styles.emptyStateMessage}>{t('EMAIL_TEMPLATES.ADD_TEMPLATE')}</span>
					<AddTemplateButton onAdd={handleAddTemplate} />
				</div>
			)}
			{detailsOpened ? (
				<TemplateDetails templateId={selectedTemplateId} onClose={handleCloseTemplateDetails} />
			) : null}
		</div>
	);
});

export const TemplatesManagerWithRoot = () => {
	const templatesRootStore = useMemo(() => TemplatesRoot.createRoot(), []);

	return (
		<StoreProvider value={templatesRootStore}>
			<TemplatesManager />
		</StoreProvider>
	);
};
