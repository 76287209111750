import { TFunction } from 'i18next';
import { CellProps, Column, FilterTypes } from 'react-table';
import { Button } from '@bringg/react-components';
import { getRoot } from 'mobx-easy';
import type { TemplateType } from '@bringg/types';
import type { Row, IdType } from 'react-table';

import RootStore from 'bringg-web/stores/root-store';
import { alphanumericCaseInsensitiveSortFunction } from 'bringg-web/components/bringg-react-table/utils';
import { DateCell, TemplateTypeCell, ActionsCell, CreatorNameCell, UsageCell } from './components';

export const columnIds = {
	NAME: 'name',
	TYPE: 'template_type',
	OWNER_USER_ID: 'owner_user_id',
	USAGE: 'usage',
	UPDATED_AT: 'updated_at',
	ACTION: 'action'
};

export const filterTypes: FilterTypes<TemplateType> = {
	byText: (rows, filterIds, value: string) => {
		return rows.filter(row => {
			const { driversStore } = getRoot<RootStore>().data;
			const searchQuery = value?.trim()?.toLowerCase();
			const ownerName = driversStore.get(row.original[columnIds.OWNER_USER_ID])?.name;

			return (
				row.original[columnIds.NAME]?.toLowerCase().includes?.(searchQuery) ||
				ownerName?.toLowerCase().includes?.(searchQuery)
			);
		});
	},
	// TODO: filter for filtering by template_type property
	byTemplateType: (rows, filterIds, value: number[]) => {
		return rows.filter(row => value.includes(row.original[columnIds.TYPE]));
	}
};

export const sortTypes = {
	// this custom alphanumeric func overrides native func in react-table
	alphanumeric: alphanumericCaseInsensitiveSortFunction,
	byUser: (rowA: Row<TemplateType>, rowB: Row<TemplateType>, columnId: IdType<TemplateType>) => {
		const { driversStore } = getRoot<RootStore>().data;

		const valueA: number = rowA.values[columnId];
		const valueB: number = rowB.values[columnId];
		const userNameA = driversStore.get(valueA)?.name ?? '';
		const userNameB = driversStore.get(valueB)?.name ?? '';

		return userNameA.localeCompare(userNameB);
	},
	byUsage: (rowA: Row<TemplateType>, rowB: Row<TemplateType>) => {
		if (!rowA.original?.usage?.length || !rowB.original?.usage?.length) {
			return 0;
		}

		const rowAUsagesQuantity = rowA.original?.usage.reduce((acc, next) => {
			return next?.ids?.length ? acc + next.ids.length : acc;
		}, 0);
		const rowBUsagesQuantity = rowB.original?.usage.reduce((acc, next) => {
			return next?.ids?.length ? acc + next.ids.length : acc;
		}, 0);

		return rowAUsagesQuantity - rowBUsagesQuantity;
	}
};

export const tableColumns = (
	t: TFunction,
	onEditTemplate: (id: number) => void,
	onDeleteTemplate: (id: number) => Promise<void>
) => {
	return [
		{
			id: columnIds.NAME,
			accessor: columnIds.NAME,
			filter: 'byText',
			Header: t('EMAIL_TEMPLATES.TEMPLATE_NAME'),
			Cell: ({ value, row }: CellProps<TemplateType>) => {
				return (
					<Button type="link" onClick={() => onEditTemplate(row.original.id)}>
						{value}
					</Button>
				);
			}
		},
		{
			id: columnIds.TYPE,
			accessor: columnIds.TYPE,
			Header: t('EMAIL_TEMPLATES.TEMPLATE_TYPE'),
			Cell: ({ value }: CellProps<TemplateType>) => {
				return <TemplateTypeCell type={value} />;
			}
		},
		{
			id: columnIds.OWNER_USER_ID,
			accessor: columnIds.OWNER_USER_ID,
			filter: 'byText',
			Header: t('EMAIL_TEMPLATES.TEMPLATE_CREATOR'),
			Cell: ({ value }: CellProps<TemplateType>) => {
				return <CreatorNameCell userId={value} />;
			},
			sortType: sortTypes.byUser
		},
		{
			id: columnIds.USAGE,
			accessor: columnIds.USAGE,
			Header: t('EMAIL_TEMPLATES.USED_IN'),
			sortType: sortTypes.byUsage,
			Cell: ({ value }: CellProps<TemplateType>) => {
				return <UsageCell templateUsage={value} />;
			}
		},
		{
			id: columnIds.UPDATED_AT,
			accessor: columnIds.UPDATED_AT,
			Header: t('EMAIL_TEMPLATES.LAST_UPDATE'),
			Cell: ({ value }: CellProps<TemplateType>) => {
				return <DateCell date={value} />;
			}
		},
		{
			id: columnIds.ACTION,
			sticky: 'right',
			disableResizing: true,
			disableSortBy: true,
			width: 85,
			Header: t('EMAIL_TEMPLATES.ACTIONS'),
			Cell: ({ row }: CellProps<TemplateType>) => {
				return (
					<ActionsCell
						id={row.original.id}
						templateName={row.original.name}
						onEdit={onEditTemplate}
						onDelete={onDeleteTemplate}
					/>
				);
			}
		}
	] as Column<TemplateType>[];
};

export const templateLanguagesFieldName = 'body';

export const DATA_TEST_IDS = {
	sendTestEmailBtn: 'sendTestEmailBtn',
	sanitizeHtmlBtn: 'sanitizeHtmlBtn',
	templateUsageText: 'templateUsageText',
	sendTestPrintOrderBtn: 'sendTestPrintOrderBtn'
};

export const DISABLE_TEMPLATE_SANITIZER_FF = 'email_template_disable_sanitizer';
