// TODO the whole page is written bad, needs to be rewritten before any significant changes in the future
// https://bringg.atlassian.net/browse/BRNGG-40638
import React, { useEffect, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getRoot } from 'mobx-easy';
import { useTranslation } from 'react-i18next';
import { VehicleType, ApplicationUuid } from '@bringg/types';
import { get } from 'lodash';

import { useStores } from 'bringg-web/recipes';
import notification from 'bringg-web/services/notification';
import VehicleProfilesTable from './vehicle-profiles-table';
import VehicleProfileForm from './vehicle-profile-form';
import { excludeTrucksForHere } from './utils';
import RootStore from '../../stores/root-store';

export enum CategoryAttributeName {
	VEHICLE_CATEGORY = 'vehicle_category',
	VEHICLE_CATEGORY_ID = 'vehicle_category_id'
}

const VehicleProfiles = () => {
	const { t } = useTranslation();
	const { vehicleTypesStore } = useStores();
	const [isLoading, setIsLoading] = useState(false);
	const [vehicleType, setVehicleType] = useState<VehicleType | null>(null);
	const [vehicleCategories, setVehicleCategories] = useState([]);

	const configuration = getRoot<RootStore>().data.merchantConfigurationsStore.findApplicationConfiguration(
		ApplicationUuid.RouteOptimizer2
	);

	useEffect(() => {
		const fetchVehicleCategories = async () => {
			const response = await getRootEnv().dashboardSdk.sdk.vehicleCategories.getAll();
			setVehicleCategories([
				...get(response, 'internal_vehicle_categories', [])
					.filter(excludeTrucksForHere(configuration?.data?.dm_engine))
					.map(category => {
						return {
							id: category,
							name: t(`VEHICLE_PROFILES.VEHICLE_CATEGORIES.${category.toUpperCase()}`),
							type: CategoryAttributeName.VEHICLE_CATEGORY
						};
					}),
				...get(response, 'vehicle_categories', []).map(category => ({
					...category,
					type: CategoryAttributeName.VEHICLE_CATEGORY_ID
				}))
			]);
		};

		fetchVehicleCategories();
	}, []);
	useEffect(() => {
		const prepareTableData = async () => {
			setIsLoading(true);
			try {
				await vehicleTypesStore.fetchAll();
			} catch (err) {
				console.error(err);
				notification.error(t('VEHICLE_PROFILES.ERROR.FAILED_TO_LOAD'));
			}

			setIsLoading(false);
		};

		prepareTableData();
	}, []);

	if (vehicleType === null) {
		return (
			<VehicleProfilesTable
				setVehicleType={setVehicleType}
				isLoading={isLoading}
				vehicleCategories={vehicleCategories}
			/>
		);
	}

	if (vehicleType) {
		return (
			<VehicleProfileForm
				vehicleType={vehicleType}
				setVehicleType={setVehicleType}
				vehicleCategories={vehicleCategories}
			/>
		);
	}
};

export default VehicleProfiles;
