import { Task, TaskStatus } from '@bringg/types';
import { Dictionary, groupBy } from 'lodash';

import { collapseLinkedTasksGenerator } from './collapse-linked-tasks-generator';
import { CollapseLinkedTasksData } from './collapse-linked-tasks.consts';

export default class CollapseLinkedTasksService {
	private reOrderAndSaveTaskIds = (
		tasks: Task[],
		tasksInCurrentPage: Map<number, Task | CollapseLinkedTasksData>,
		tasksInCurrentPageByRunIdMap: Map<number, (Task | CollapseLinkedTasksData)[]>,
		collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>,
		taskIdToCollapseTaskId: Map<number, number>
	) => {
		const tasksByRunId: Dictionary<Task[]> = groupBy(tasks, task => task.run_id);

		Object.entries(tasksByRunId).forEach(([key, runTasks]) => {
			const keyAsNumber = Number(key);
			const runId = isNaN(keyAsNumber) ? undefined : keyAsNumber;

			const previousTasksWithCollapsedTasks = tasksInCurrentPageByRunIdMap.get(runId) || [];
			const needToReOrderAgain = previousTasksWithCollapsedTasks.length && runId !== undefined;

			const tasksByIdToReorder = new Map<number, Task>();

			if (needToReOrderAgain) {
				this.extractTasksFromCollapsedTasks(previousTasksWithCollapsedTasks, collapseLinkTasksMap).forEach(
					task => tasksByIdToReorder.set(task.id, task)
				);
				const collapsedTasks = previousTasksWithCollapsedTasks.filter(task => task.id < 0);
				collapsedTasks.forEach(collapsedTask => {
					const collapseLinkedTasksData = collapseLinkTasksMap.get(collapsedTask.id);
					collapseLinkedTasksData.tasks.forEach(task => {
						if (tasksInCurrentPage.has(task.id)) {
							tasksInCurrentPage.delete(task.id);
						}
						if (taskIdToCollapseTaskId.has(task.id)) {
							taskIdToCollapseTaskId.delete(task.id);
						}
					});
					const runId = collapseLinkedTasksData.run_id;
					if (tasksInCurrentPageByRunIdMap.has(runId)) {
						tasksInCurrentPageByRunIdMap.delete(runId);
					}
					collapseLinkTasksMap.delete(collapsedTask.id);

					if (tasksInCurrentPage.has(collapsedTask.id)) {
						tasksInCurrentPage.delete(collapsedTask.id);
					}
				});
				if (tasksInCurrentPageByRunIdMap.has(runId)) {
					tasksInCurrentPageByRunIdMap.delete(runId);
				}
			}

			runTasks.forEach(task => {
				tasksByIdToReorder.set(task.id, task);
			});

			const tasksToReorder = Array.from(tasksByIdToReorder.values());
			const orderedTasks =
				runId === undefined
					? tasksToReorder
					: collapseLinkedTasksGenerator.reOrderTasksByCustomer(tasksToReorder, collapseLinkTasksMap);

			this.saveResult(
				runId,
				orderedTasks,
				tasksInCurrentPage,
				tasksInCurrentPageByRunIdMap,
				collapseLinkTasksMap,
				taskIdToCollapseTaskId
			);
		});
	};

	buildTasks = (
		tasks: Task[],
		tasksInCurrentPage: Map<number, Task | CollapseLinkedTasksData>,
		tasksInCurrentPageByRunId: Map<number, (Task | CollapseLinkedTasksData)[]>,
		collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>,
		taskIdToCollapseTaskId: Map<number, number>
	): Task[] => {
		const sortedTasks = collapseLinkedTasksGenerator.sortTasksByPriority(tasks);
		this.reOrderAndSaveTaskIds(
			sortedTasks,
			tasksInCurrentPage,
			tasksInCurrentPageByRunId,
			collapseLinkTasksMap,
			taskIdToCollapseTaskId
		);

		const results = this.extractReorderedTasks(
			Array.from(tasksInCurrentPageByRunId.values()).flat(),
			collapseLinkTasksMap
		);
		results.forEach(task => {
			tasksInCurrentPage.set(task.id, task);
		});
		return results;
	};

	private extractReorderedTasks = (
		tasks: (Task | CollapseLinkedTasksData)[],
		collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>
	): Task[] => {
		const results = new Map();

		tasks.forEach(task => {
			if (collapseLinkTasksMap.has(task.id)) {
				const collapseData = collapseLinkTasksMap.get(task.id);
				const collapsedLinkedTask = collapseLinkedTasksGenerator.convertCollapseLinkedTasksToTask(collapseData);
				collapseData.tasks.forEach(item => {
					if (results.has(item.id)) {
						results.delete(item.id);
					}
				});
				results.set(collapsedLinkedTask.id, collapsedLinkedTask);
			} else {
				results.set(task.id, task);
			}
		});

		return Array.from(results.values());
	};

	saveResult = (
		runId: number,
		tasks: (Task | CollapseLinkedTasksData)[],
		tasksInCurrentPage: Map<number, Task | CollapseLinkedTasksData>,
		tasksInCurrentPageByRunId: Map<number, (Task | CollapseLinkedTasksData)[]>,
		collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>,
		taskIdToCollapseTaskId: Map<number, number>
	) => {
		const collapsedLinkedTasks: CollapseLinkedTasksData[] = tasks.filter(
			task => task.id < 0
		) as CollapseLinkedTasksData[];

		collapsedLinkedTasks.forEach((collapseLinkTask: CollapseLinkedTasksData) => {
			collapseLinkTask.tasks.forEach(task => {
				if (tasksInCurrentPage.has(task.id)) {
					tasksInCurrentPage.delete(task.id);
				}
				taskIdToCollapseTaskId.set(task.id, collapseLinkTask.id);
			});
			collapseLinkTasksMap.set(collapseLinkTask.id, collapseLinkTask);
		});

		tasksInCurrentPageByRunId.set(runId, tasks);
	};

	private extractTasksFromCollapsedTasks = (
		tasks: (Task | CollapseLinkedTasksData)[],
		collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>
	): Task[] => {
		const extractedTasks = [];

		tasks.forEach(task => {
			if (collapseLinkTasksMap.has(task.id)) {
				const collapseLinkedTasksData = collapseLinkTasksMap.get(task.id);
				extractedTasks.push(...collapseLinkedTasksData.tasks);
			} else {
				extractedTasks.push(task);
			}
		});

		return extractedTasks;
	};

	private updateTaskInCollapsedLinkedTasks = (
		updatedTask: Task,
		tasksInCurrentPage: Map<number, Task | CollapseLinkedTasksData>,
		tasksInCurrentPageByRunId: Map<number, (Task | CollapseLinkedTasksData)[]>,
		collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>,
		taskIdToCollapseTaskId: Map<number, number>,
		runIdsToUpdate: Set<number>
	) => {
		if (!taskIdToCollapseTaskId.has(updatedTask.id)) {
			return;
		}
		const collapseTaskParentId = taskIdToCollapseTaskId.get(updatedTask.id);
		const collapseTaskParent = collapseLinkTasksMap.get(collapseTaskParentId);
		const runId = collapseTaskParent.run_id;

		runIdsToUpdate.add(runId);

		if (tasksInCurrentPageByRunId.has(runId)) {
			const runTasks = tasksInCurrentPageByRunId.get(runId);
			const updatedTasks = [...runTasks, ...collapseTaskParent.tasks].filter(task => {
				const isCollapseTask = collapseLinkTasksMap.has(task.id);
				const isReadyToExecuteEqual =
					!isCollapseTask && (task as Task).ready_to_execute === updatedTask.ready_to_execute;
				return (
					task.id !== updatedTask.id &&
					task.id !== collapseTaskParent.id &&
					(isCollapseTask || isReadyToExecuteEqual)
				);
			});

			if (!updatedTasks.length) {
				tasksInCurrentPageByRunId.delete(runId);
			} else {
				tasksInCurrentPageByRunId.set(runId, updatedTasks);
			}

			if (tasksInCurrentPage.has(updatedTask.id)) {
				tasksInCurrentPage.delete(updatedTask.id);
				collapseTaskParent.tasks = collapseTaskParent.tasks.filter(task => task.id !== updatedTask.id);
				if (collapseTaskParent.tasks.length === 1) {
					tasksInCurrentPage.set(collapseTaskParent.tasks[0].id, collapseTaskParent.tasks[0]);
					collapseTaskParent.tasks = [];
				}
			}

			if (tasksInCurrentPage.has(collapseTaskParent.id)) {
				tasksInCurrentPage.delete(collapseTaskParent.id);
			}
			if (!collapseTaskParent.tasks.length) {
				taskIdToCollapseTaskId.delete(updatedTask.id);
			}
		}
	};

	private updateTaskNotInCollapseLinkedTasks = (
		updatedTask: Task,
		tasksInCurrentPage: Map<number, Task | CollapseLinkedTasksData>,
		tasksInCurrentPageByRunId: Map<number, (Task | CollapseLinkedTasksData)[]>
	) => {
		if (tasksInCurrentPage.has(updatedTask.id)) {
			tasksInCurrentPage.delete(updatedTask.id);
		}

		const runId = updatedTask.run_id ?? undefined;
		if (tasksInCurrentPageByRunId.has(runId)) {
			const updatedTasks = tasksInCurrentPageByRunId.get(runId).filter(task => task.id !== updatedTask.id);
			if (!updatedTasks.length) {
				tasksInCurrentPageByRunId.delete(runId);
				return;
			}
			tasksInCurrentPageByRunId.set(runId, updatedTasks);
		}
	};

	public onTaskCreatedOrUpdated = (
		updatedTask: Task,
		tasksInCurrentPage: Map<number, Task | CollapseLinkedTasksData>,
		tasksNotInCurrentPage: Map<number, Task | CollapseLinkedTasksData>,
		tasksInCurrentPageByRunId: Map<number, (Task | CollapseLinkedTasksData)[]>,
		tasksNotInCurrentPageByRunId: Map<number, (Task | CollapseLinkedTasksData)[]>,
		collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>,
		taskIdToCollapseTaskId: Map<number, number>,
		previousRunId: number,
		previousReadyToExecute: boolean
	) => {
		if (updatedTask.status === TaskStatus.Cancelled || updatedTask.status === TaskStatus.Done) {
			this.onTaskDeleted(
				updatedTask,
				tasksInCurrentPage,
				tasksInCurrentPageByRunId,
				collapseLinkTasksMap,
				taskIdToCollapseTaskId
			);
			return;
		}

		const runIdsToUpdate = new Set<number>();
		if (previousRunId) {
			runIdsToUpdate.add(previousRunId);
		}
		runIdsToUpdate.add(updatedTask.run_id);

		if (taskIdToCollapseTaskId.has(updatedTask.id) && updatedTask.run_id == null) {
			taskIdToCollapseTaskId.delete(updatedTask.id);
		}
		if (taskIdToCollapseTaskId.has(updatedTask.id)) {
			this.updateTaskInCollapsedLinkedTasks(
				updatedTask,
				tasksInCurrentPage,
				tasksInCurrentPageByRunId,
				collapseLinkTasksMap,
				taskIdToCollapseTaskId,
				runIdsToUpdate
			);
			this.updateTaskInCollapsedLinkedTasks(
				updatedTask,
				tasksNotInCurrentPage,
				tasksNotInCurrentPageByRunId,
				collapseLinkTasksMap,
				taskIdToCollapseTaskId,
				runIdsToUpdate
			);
		} else {
			this.updateTaskNotInCollapseLinkedTasks(updatedTask, tasksInCurrentPage, tasksInCurrentPageByRunId);
			this.updateTaskNotInCollapseLinkedTasks(updatedTask, tasksNotInCurrentPage, tasksNotInCurrentPageByRunId);
		}

		const tasksToBuild = [updatedTask];

		runIdsToUpdate.forEach(runId => {
			const runTasks =
				previousReadyToExecute !== updatedTask.ready_to_execute
					? tasksNotInCurrentPageByRunId.has(runId)
						? tasksNotInCurrentPageByRunId.get(runId)
						: []
					: tasksInCurrentPageByRunId.has(runId)
					? tasksInCurrentPageByRunId.get(runId)
					: [];
			tasksToBuild.push(...this.extractTasksFromCollapsedTasks(runTasks, collapseLinkTasksMap));
		});

		return this.buildTasks(
			tasksToBuild,
			previousReadyToExecute !== updatedTask.ready_to_execute ? tasksNotInCurrentPage : tasksInCurrentPage,
			previousReadyToExecute !== updatedTask.ready_to_execute
				? tasksNotInCurrentPageByRunId
				: tasksInCurrentPageByRunId,
			collapseLinkTasksMap,
			taskIdToCollapseTaskId
		);
	};

	private deleteTaskInCollapseLinkedTasks = (
		deletedTask: Task,
		tasksInCurrentPage: Map<number, Task | CollapseLinkedTasksData>,
		tasksInCurrentPageByRunId: Map<number, (Task | CollapseLinkedTasksData)[]>,
		collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>,
		taskIdToCollapseTaskId: Map<number, number>
	) => {
		const collapseTaskParentId = taskIdToCollapseTaskId.get(deletedTask.id);
		const collapseTaskParentData = collapseLinkTasksMap.get(collapseTaskParentId);

		const runId = deletedTask.run_id;

		if (tasksInCurrentPage.has(collapseTaskParentId)) {
			tasksInCurrentPage.delete(collapseTaskParentId);
		}
		if (!tasksInCurrentPageByRunId.has(runId)) {
			return;
		}

		const updatedTasks = [];
		const runTasks = tasksInCurrentPageByRunId.get(runId);
		updatedTasks.push(
			...[...runTasks, ...collapseTaskParentData.tasks].filter(
				task => task.id !== deletedTask.id && task.id !== collapseTaskParentId
			)
		);

		if (!updatedTasks.length) {
			tasksInCurrentPageByRunId.delete(runId);
		} else {
			tasksInCurrentPageByRunId.set(runId, updatedTasks);
		}
		return updatedTasks.filter(Boolean).filter(task => task.id > 0);
	};
	private deleteTaskNotInCollapseLinkedTasks = (
		deletedTask: Task,
		tasksInCurrentPageByRunId: Map<number, (Task | CollapseLinkedTasksData)[]>
	) => {
		const updatedTasks = [];
		const runId = deletedTask.run_id;
		const runTasks = tasksInCurrentPageByRunId.get(runId);
		const deletedTaskIndex = runTasks.findIndex(task => task?.id === deletedTask.id);
		if (deletedTaskIndex >= 0) {
			delete runTasks[deletedTaskIndex];
			updatedTasks.push(...runTasks);
		}
		return updatedTasks.filter(Boolean).filter(task => task.id > 0);
	};

	public onTaskDeleted = (
		deletedTask: Task,
		tasksInCurrentPage: Map<number, Task | CollapseLinkedTasksData>,
		tasksInCurrentPageByRunId: Map<number, (Task | CollapseLinkedTasksData)[]>,
		collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>,
		taskIdToCollapseTaskId: Map<number, number>
	) => {
		const runId = deletedTask.run_id;

		if (tasksInCurrentPage.has(deletedTask.id)) {
			tasksInCurrentPage.delete(deletedTask.id);
		}

		if (!tasksInCurrentPageByRunId.has(runId)) {
			return;
		}
		const updatedTasks = [];

		if (taskIdToCollapseTaskId.has(deletedTask.id)) {
			updatedTasks.push(
				...this.deleteTaskInCollapseLinkedTasks(
					deletedTask,
					tasksInCurrentPage,
					tasksInCurrentPageByRunId,
					collapseLinkTasksMap,
					taskIdToCollapseTaskId
				)
			);
		} else {
			updatedTasks.push(...this.deleteTaskNotInCollapseLinkedTasks(deletedTask, tasksInCurrentPageByRunId));
		}

		if (updatedTasks.length) {
			return this.buildTasks(
				updatedTasks,
				tasksInCurrentPage,
				tasksInCurrentPageByRunId,
				collapseLinkTasksMap,
				taskIdToCollapseTaskId
			);
		}
	};
}
