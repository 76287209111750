import React, { useMemo, useRef } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useFilters, useFlexLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import { type TemplateType } from '@bringg/types';
import { useOnScreen } from '@bringg-frontend/hooks';
import { useSticky } from 'react-table-sticky';

import { AddTemplateButton } from '../';
import { tableColumns, filterTypes, sortTypes } from '../../consts';
import { BasicTableContainer, BringgReactTable, ListChildRow } from 'bringg-web/components/bringg-react-table';
import { useControlledTableState } from '../../hooks';
import { useTableFilters } from '../../hooks';
import FiltersPanel from '../../filter-panel';
import useTemplatesStore from '../../store/helpers/use-templates-store';

import styles from './templates-table.module.scss';

interface Props {
	componentContainerRef: React.RefObject<HTMLElement>;
	onAddTemplate: () => void;
	onEditTemplate: (id: number) => void;
}

export const TemplatesTable = observer(({ componentContainerRef, onAddTemplate, onEditTemplate }: Props) => {
	const { t } = useTranslation();

	const {
		templatesStore: { all: allTemplates, isLoading, deleteTemplateById }
	} = useTemplatesStore();

	const tableContainerRef = useRef<HTMLDivElement>(null);
	const isVisible = useOnScreen(componentContainerRef);

	const columns = useMemo(() => {
		return tableColumns(t, onEditTemplate, deleteTemplateById);
	}, [t, onEditTemplate]);

	const tableFiltersState = useTableFilters();
	const useControlledState = useControlledTableState(
		tableFiltersState.filters,
		isVisible,
		isLoading,
		tableContainerRef,
		columns
	);

	const tableInstance = useTable<TemplateType>(
		{
			filterTypes,
			data: allTemplates,
			columns,
			sortTypes,
			useControlledState
		},
		useFlexLayout,
		useFilters,
		useSortBy,
		useSticky,
		useResizeColumns
	);

	return (
		<>
			<div className={styles.templatesHeader}>
				<FiltersPanel filtersState={tableFiltersState} />
				<AddTemplateButton onAdd={onAddTemplate} />
			</div>

			<div className={styles.templatesTable} ref={tableContainerRef}>
				<BringgReactTable
					TableContainerComponent={BasicTableContainer}
					RenderRow={ListChildRow}
					tableInstance={tableInstance}
				/>
			</div>
		</>
	);
});
