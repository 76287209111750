import React, { useCallback, useMemo, useState } from 'react';

import { observer, useObserver } from 'mobx-react';
import moment, { Moment } from 'moment';
import _sortBy from 'lodash/sortBy';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _partition from 'lodash/partition';
import { useTranslation } from 'react-i18next';
import { Modal } from '@bringg/react-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TimelineTimeAxisOption } from 'vis-timeline/peer';
import { GanttTimeline, GanttTimeLineItem, GroupRow, TooltipEventOption } from '@bringg-frontend/gantt-timeline';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import { DeliveryBlockBreakId } from '../stores/domain-objects/delivery-block-break';
import Team from 'bringg-web/stores/teams/domain-object/team';
import { useStores } from 'bringg-web/recipes';
import TimezoneService from 'bringg-web/services/timezone/timezone-service';
import Driver from 'bringg-web/stores/drivers/domain-object/driver';
import DeliveryBlock from '../stores/domain-objects/delivery-block';
import {
	calculateAllBlocksBreaksLength,
	createDeliveryBlockSlotIndexes,
	createTimeLineItemsForDeliveryBlock,
	createTimeLineItemsForDeliveryBlockBreaks,
	createTimelineItemsForDeliveryBlockRecharges,
	createTimeLineRowsForDeliveryBlock,
	DeliveryBlockTimeLineCustomData,
	DeliveryBlockTimeLineIndex,
	getBusyDriversAtTimeWindow,
	getDriversCountByTime
} from './delivery-blocks-timeline-logic';
import MoveBlockConfirmationModal from '../move-block-confirmation-content/move-block-confirmation-content';
import DeliveryBlockTimelineTooltip from '../delivery-block-timline-tooltip/delivery-block-timeline-tooltip';
import { useHasCombinedOptimization } from 'bringg-web/features/delivery-blocks-v2/utils/use-has-combined-optimization';
import { useDeliveryBlocksStore } from '../stores/delivery-blocks-root-store';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';
import { teamHasFeatureFlag } from 'bringg-web/utils/feature-flags';

interface Props {
	currentDay: string;
	selectedTeam: Team;
	onDeliveryBlockClicked: (deliveryBlockId: number) => void;
	onDeliveryBlockTimeUpdated: (deliveryBlockId: number, start: Moment, end: Moment) => void;
	onDeliveryBlockBreakTimeUpdated: (
		deliveryBlockId: number,
		breakId: DeliveryBlockBreakId,
		actualBreakId: number,
		start: Moment,
		end: Moment
	) => void;
	searchValue?: string;
}

export type ModalConfirmationData = {
	deliveryBlockId: number;
	startTime: string;
	endTime: string;
	driversWithConflictedBlocks: Map<Driver, DeliveryBlock>;
};

export const TIMELINE_START_HOUR = 8;
export const TIMELINE_END_HOUR = 24;
export const TIMELINE_STEP: TimelineTimeAxisOption = { step: 1, scale: 'hour' };

const timelineDefaultOptions = {
	overlapItems: true,
	showCurrentTime: false,
	constAxisStep: TIMELINE_STEP,
	showRemoveRowButton: false,
	tooltipBy: TooltipEventOption.Hover,
	supportItemDurationChange: true,
	zoomMin: 14 * 60 * 60 * 1000, // 15 hours in millis
	zoomMax: 48 * 60 * 60 * 1000 // 2 days in millis
};

export type AvailableDriver = Driver;
export type UnavailableDriver = Driver;

const isSameDayInLocalTimezone = (teamTimezoneDate: Moment, localTimeZoneDate: Moment) => {
	return (
		teamTimezoneDate.date() === localTimeZoneDate.date() &&
		teamTimezoneDate.month() === localTimeZoneDate.month() &&
		teamTimezoneDate.year() === localTimeZoneDate.year()
	);
};

const DeliveryBlocksTimeLineCalendar: React.FC<Props> = ({
	currentDay,
	selectedTeam,
	onDeliveryBlockClicked,
	onDeliveryBlockTimeUpdated,
	onDeliveryBlockBreakTimeUpdated,
	searchValue
}) => {
	const { merchantConfigurationsStore } = useStores();
	const { deliveryBlocksStore } = useDeliveryBlocksStore();
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [modalConfirmationData, setModalConfirmationData] = useState<ModalConfirmationData>(null);
	const isCombinedOptimization = useHasCombinedOptimization(selectedTeam);
	const enableVehicles = merchantConfigurationsStore.configuration.enable_vehicles || false;

	const teamTimezone = timezoneProvider.getTimezoneByTeamId(selectedTeam.id);
	const deliveryBlockTooltipRender = useCallback(([timelineItem]) => {
		const { deliveryBlockId, breakId, rechargeStartTime } =
			timelineItem.customData as DeliveryBlockTimeLineCustomData;
		const deliveryBlock = deliveryBlocksStore.get(deliveryBlockId);

		if (!deliveryBlock) {
			return null;
		}

		let recharge;
		if (rechargeStartTime) {
			const rechargeDuration =
				(merchantConfigurationsStore.initialLoadingTimeMinutes || 0) +
				(merchantConfigurationsStore.initialUnloadingTimeMinutes || 0);
			recharge = {
				startTime: rechargeStartTime,
				endTime: moment(rechargeStartTime).tz(teamTimezone).add(rechargeDuration, 'minutes').toISOString()
			};
		}

		return <DeliveryBlockTimelineTooltip deliveryBlock={deliveryBlock} breakId={breakId} recharge={recharge} />;
	}, []);

	const currentDayDeliveryBlocks = useMemo(() => {
		const momentTz = TimezoneService.getMomentTimezone(teamTimezone);

		return _sortBy(
			selectedTeam?.deliveryBlocks?.filter(({ start_time, end_time }) => {
				return (
					isSameDayInLocalTimezone(momentTz(start_time), moment(currentDay).tz(teamTimezone)) ||
					isSameDayInLocalTimezone(momentTz(end_time), moment(currentDay).tz(teamTimezone))
				);
			}),
			['start_time']
		);
	}, [currentDay, selectedTeam, selectedTeam?.deliveryBlocks, selectedTeam?.localizationTimezone]);

	const driversPerTimeBlock = useMemo<Map<number, number>>(() => {
		return getDriversCountByTime(currentDayDeliveryBlocks);
	}, [currentDayDeliveryBlocks]);

	// @ts-ignore gantt type on formatXaxis - Date | Moment but its actually Moment
	const timelineOptions = useMemo<GanttOptions>(() => {
		const momentTz = TimezoneService.getMomentTimezone(teamTimezone);

		const currentDate = moment(currentDay).tz(teamTimezone).date();
		const currentMonth = moment(currentDay).tz(teamTimezone).month();
		const currentYear = moment(currentDay).tz(teamTimezone).year();

		return {
			...timelineDefaultOptions,
			displayStartTime: momentTz()
				.set({ year: currentYear, month: currentMonth, date: currentDate, hour: TIMELINE_START_HOUR })
				.startOf('hour'),
			displayEndTime: momentTz()
				.set({ year: currentYear, month: currentMonth, date: currentDate, hour: TIMELINE_END_HOUR })
				.startOf('hour'),
			timezone: teamTimezone,
			formatXaxis: (date: Moment) => {
				const driversCount = driversPerTimeBlock.get(date.get('hours')) || 0;
				const driversText = `${driversCount} ${t('DELIVERY_BLOCK.DRIVERS_LABEL')}`;
				const dateText = date.format('HH:mm');

				return ` ${dateText}\n ${driversText}`;
			}
		};
	}, [currentDay, selectedTeam, driversPerTimeBlock]);

	const deliveryBlocksDriversToAssign = useMemo<Map<number, [AvailableDriver[], UnavailableDriver[]]>>(() => {
		if (teamHasFeatureFlag(selectedTeam, 'disable_compute_delivery_blocks_drivers_to_assign')) {
			return new Map(
				currentDayDeliveryBlocks.map(deliveryBlock => [
					deliveryBlock.id,
					[deliveryBlock.team?.drivers || [], []]
				])
			);
		}

		const deliveryBlockUnassignedDriverEntries: [DeliveryBlock, Driver[]][] = currentDayDeliveryBlocks.map(
			deliveryBlock => {
				const unassignedDrivers = _filter(deliveryBlock.team?.drivers, ({ id: driverId }) =>
					isCombinedOptimization
						? !deliveryBlock.assignedDriversByResources.get(driverId)
						: !deliveryBlock.user_ids.includes(driverId)
				);
				return [deliveryBlock, unassignedDrivers];
			}
		);

		const deliveryBlockAvailableDrivers: [number, [AvailableDriver[], UnavailableDriver[]]][] =
			deliveryBlockUnassignedDriverEntries.map(([deliveryBlock, unassignedDrivers]) => {
				const [availableDrivers, unavailableDrivers] = _partition(unassignedDrivers, unassignedDriver =>
					unassignedDriver.isAvailableAtRequestedBlock(deliveryBlock.start_time, deliveryBlock.end_time)
				);
				return [deliveryBlock.id, [availableDrivers, unavailableDrivers]];
			});

		return new Map<number, [Driver[], Driver[]]>(deliveryBlockAvailableDrivers);
	}, [currentDayDeliveryBlocks, isCombinedOptimization]);

	const timeLineSlotIndexes = useMemo<DeliveryBlockTimeLineIndex[]>(() => {
		return createDeliveryBlockSlotIndexes(
			currentDayDeliveryBlocks,
			searchValue,
			isCombinedOptimization,
			enableVehicles
		);
	}, [currentDayDeliveryBlocks, searchValue, enableVehicles, isCombinedOptimization]);

	const rows = useMemo<GroupRow[]>(() => {
		return timeLineSlotIndexes.flatMap(([deliveryBlockId, startIndex]) => {
			const [availableDrivers, unavailableDrivers] = deliveryBlocksDriversToAssign.get(deliveryBlockId) || [
				[],
				[]
			];
			const deliveryBlock = deliveryBlocksStore.get(deliveryBlockId);

			return createTimeLineRowsForDeliveryBlock(
				deliveryBlock,
				availableDrivers,
				unavailableDrivers,
				startIndex,
				searchValue,
				t,
				isCombinedOptimization,
				enableVehicles
			);
		});
	}, [
		timeLineSlotIndexes,
		deliveryBlocksDriversToAssign,
		deliveryBlocksStore,
		searchValue,
		t,
		isCombinedOptimization,
		enableVehicles
	]);

	const items = useMemo(() => {
		const driverSlotsItems = timeLineSlotIndexes.flatMap(([deliveryBlockId, startIndex]) =>
			createTimeLineItemsForDeliveryBlock(
				deliveryBlocksStore.get(deliveryBlockId),
				startIndex,
				searchValue,
				isCombinedOptimization,
				enableVehicles,
				teamTimezone
			)
		);

		const breakItems = timeLineSlotIndexes.flatMap(([deliveryBlockId, startIndex]) =>
			createTimeLineItemsForDeliveryBlockBreaks(
				deliveryBlocksStore.get(deliveryBlockId),
				startIndex,
				searchValue,
				t,
				isCombinedOptimization,
				enableVehicles,
				teamTimezone
			)
		);

		const rechargeItems = timeLineSlotIndexes.flatMap(([deliveryBlockId, startIndex]) =>
			createTimelineItemsForDeliveryBlockRecharges(
				deliveryBlocksStore.get(deliveryBlockId),
				startIndex,
				(merchantConfigurationsStore.initialLoadingTimeMinutes || 0) +
					(merchantConfigurationsStore.initialUnloadingTimeMinutes || 0),
				teamTimezone
			)
		);

		return [...rechargeItems, ...breakItems, ...driverSlotsItems];
	}, [timeLineSlotIndexes, deliveryBlocksStore, searchValue, isCombinedOptimization, enableVehicles, t]);

	const deliveryBlocksTotalHours = useMemo(
		() =>
			currentDayDeliveryBlocks.reduce(
				(totalHours, { start_time, end_time, capacity }) =>
					totalHours + (moment.duration(moment(end_time).diff(start_time)).asMinutes() / 60) * capacity,
				0
			),
		[currentDayDeliveryBlocks]
	);

	const deliveryBlockWithoutBreaksTotalHours = useMemo(() => {
		const totalBreaksTime = calculateAllBlocksBreaksLength(currentDayDeliveryBlocks);

		return deliveryBlocksTotalHours - totalBreaksTime / 60;
	}, [deliveryBlocksTotalHours, currentDayDeliveryBlocks]);

	const nameField =
		enableVehicles && isCombinedOptimization
			? `\n\n${t('DELIVERY_BLOCKS.RESOURCE_NAME')}`
			: `\n\n${t('GLOBAL.DRIVER_NAME')}`;
	const typeField =
		enableVehicles && isCombinedOptimization
			? `\n\n${t('DELIVERY_BLOCKS.RESOURCE_TYPE')}`
			: `\n\n${t('GLOBAL.USER_ROLE')}`;

	const columns = useMemo(
		() => [
			{
				fieldName: 'driver',
				display: nameField,
				width: 130
			},
			{ fieldName: 'userRole', display: typeField, width: 130 },
			{
				fieldName: 'totalHoursWithBreaks',
				display: (
					<>
						{t('DELIVERY_BLOCKS.TOTAL_WITH_BREAKS')}
						<div className="total-value">{deliveryBlocksTotalHours.toFixed(1).replace(/\.0$/, '')}</div>
					</>
				),
				width: 50
			},
			{
				fieldName: 'totalHoursWithoutBreaks',
				display: (
					<>
						{t('DELIVERY_BLOCKS.TOTAL_WITHOUT_BREAKS')}
						<div className="total-value">
							{deliveryBlockWithoutBreaksTotalHours.toFixed(1).replace(/\.0$/, '')}
						</div>
					</>
				),
				width: 50
			}
		],
		[nameField, typeField, t, deliveryBlocksTotalHours, deliveryBlockWithoutBreaksTotalHours]
	);

	const onTimeLineItemClicked = useCallback(
		([item]: GanttTimeLineItem[]) => {
			const { deliveryBlockId } = item.customData as DeliveryBlockTimeLineCustomData;
			onDeliveryBlockClicked(deliveryBlockId);
		},
		[onDeliveryBlockClicked]
	);

	const onMove = useCallback(
		async (item: GanttTimeLineItem) => {
			const { start, end } = item;
			const { deliveryBlockId, breakId, actualBreakId } = item.customData as DeliveryBlockTimeLineCustomData;

			if (!_isNil(breakId)) {
				onDeliveryBlockBreakTimeUpdated(deliveryBlockId, breakId, actualBreakId, moment(start), moment(end));
				return Promise.resolve(true);
			}

			const busyDriversWithNewTime = getBusyDriversAtTimeWindow(deliveryBlocksStore, deliveryBlockId, start, end);

			if (_isEmpty(busyDriversWithNewTime)) {
				onDeliveryBlockTimeUpdated(deliveryBlockId, moment(start), moment(end));
				return Promise.resolve(true);
			}

			setModalConfirmationData({
				deliveryBlockId,
				startTime: start,
				endTime: end,
				driversWithConflictedBlocks: busyDriversWithNewTime
			});
			toggleConfirmationModal();

			return Promise.resolve(true);
		},
		[onDeliveryBlockTimeUpdated, onDeliveryBlockBreakTimeUpdated, deliveryBlocksStore]
	);

	const handleMoveBlockWithConflicts = () => {
		const { deliveryBlockId, startTime, endTime } = modalConfirmationData;
		onDeliveryBlockTimeUpdated(deliveryBlockId, moment(startTime), moment(endTime));
		toggleConfirmationModal();
	};

	const cancelMoveBlockWithConflicts = () => {
		const { deliveryBlockId } = modalConfirmationData;
		const deliveryBlock = deliveryBlocksStore.get(deliveryBlockId);
		onDeliveryBlockTimeUpdated(deliveryBlockId, moment(deliveryBlock.start_time), moment(deliveryBlock.end_time));
		toggleConfirmationModal();
	};

	const toggleConfirmationModal = () => setIsModalOpen(isOpen => !isOpen);

	return useObserver(() => (
		<div className="delivery-blocks-timeline-calendar">
			<GanttTimeline
				onItemClicked={onTimeLineItemClicked}
				items={items}
				columns={columns}
				onItemMoveEnded={onMove}
				rows={rows}
				options={timelineOptions}
				toolTipRender={deliveryBlockTooltipRender}
			/>
			<Modal
				title={t('GLOBAL.UNAVAILABLE_DRIVERS')}
				visible={isModalOpen}
				onCancel={cancelMoveBlockWithConflicts}
				onOk={handleMoveBlockWithConflicts}
				okText={t('DELIVERY_BLOCKS.MOVE_BLOCK')}
			>
				<MoveBlockConfirmationModal {...modalConfirmationData} timezone={teamTimezone} />
			</Modal>
		</div>
	));
};

export default withErrorBoundary(observer(DeliveryBlocksTimeLineCalendar));
