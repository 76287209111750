import { PickupDropoffOption, Task, TaskStatus } from '@bringg/types';
import { cloneDeep } from 'lodash';

import { CollapseLinkedTasksData } from './collapse-linked-tasks.consts';

const getLastWayPoint = (task: Task) => {
	return task.way_points[task.way_points.length - 1];
};

const processTasks = (tasks: Task[]) => {
	let minRank = Infinity;

	let minNoEarlierThan = null;
	let minEta = null;
	let minFirstAttemptNoEarlierThan = null;
	let minWayPointScheduledAt = null;
	let minReservedUntil = null;
	let minScheduledAt = null;

	let maxNoLaterThan = null;
	let maxPreparationReadyForPickupTimePlanned = null;
	let maxFirstScheduledArrival = null;
	let maxCreatedAt = null;
	let maxEtl = null;

	let sumMaxTotalWeight = 0;
	let sumInventoryTotalQuantity = 0;
	let sumTotalPrice = 0;

	const sumMaxHandlingUnits = {};
	const requiredSkills = new Set<string>();
	const servicePlanNames = new Set<string>();

	tasks.forEach(task => {
		const lastWayPoint = getLastWayPoint(task);

		minRank = Math.min(minRank, task.rank || Infinity);

		maxPreparationReadyForPickupTimePlanned = Math.max(
			maxPreparationReadyForPickupTimePlanned,
			new Date(task.preparation_ready_for_pickup_time_planned || 0).getTime()
		);

		maxCreatedAt = Math.max(maxCreatedAt, new Date(task.created_at || 0).getTime());
		sumMaxTotalWeight += task.aggregations?.max_weight || 0;
		sumInventoryTotalQuantity += task.aggregations?.inventory_total_quantity || 0;
		sumTotalPrice += task.total_price || 0;

		const taskHandlingUnits = task.aggregations?.max_handling_units;
		if (taskHandlingUnits) {
			Object.entries(taskHandlingUnits).forEach(([key, value]) => {
				const currentValue = sumMaxHandlingUnits[key] ?? 0;
				sumMaxHandlingUnits[key] = currentValue + value;
			});
		}

		task.required_skills?.forEach(skill => requiredSkills.add(skill));
		servicePlanNames.add(task.service_plan?.name);

		if (task.reserved_until) {
			minReservedUntil = Math.min(minReservedUntil || Infinity, new Date(task.reserved_until).getTime());
		}

		if (task.scheduled_at) {
			minScheduledAt = Math.min(minScheduledAt || Infinity, new Date(task.scheduled_at).getTime());
		}
		if (lastWayPoint.first_attempt_promise_no_earlier_than) {
			minFirstAttemptNoEarlierThan = Math.min(
				minFirstAttemptNoEarlierThan || Infinity,
				new Date(lastWayPoint.first_attempt_promise_no_earlier_than).getTime()
			);
		}

		if (lastWayPoint.no_earlier_than) {
			minNoEarlierThan = Math.min(minNoEarlierThan || Infinity, new Date(lastWayPoint.no_earlier_than).getTime());
		}

		maxFirstScheduledArrival = Math.max(
			maxFirstScheduledArrival,
			new Date(lastWayPoint.scheduled_arrival).getTime()
		);
		maxNoLaterThan = Math.max(maxNoLaterThan, new Date(lastWayPoint.no_later_than || 0).getTime());

		maxEtl = Math.max(maxEtl, new Date(lastWayPoint.etl || 0).getTime());
		if (lastWayPoint.eta) {
			minEta = Math.min(minEta || Infinity, new Date(lastWayPoint.eta).getTime());
		}
		if (lastWayPoint.scheduled_at) {
			minWayPointScheduledAt = Math.min(
				minWayPointScheduledAt || Infinity,
				new Date(lastWayPoint.scheduled_at).getTime()
			);
		}
	});

	return {
		minRank: minRank === Infinity ? 0 : minRank,
		maxPreparationReadyForPickupTimePlanned,
		maxFirstScheduledArrival,
		maxCreatedAt,
		minNoEarlierThan,
		minFirstAttemptNoEarlierThan,
		maxNoLaterThan,
		maxEtl,
		minEta,
		minWayPointScheduledAt,
		sumMaxTotalWeight,
		sumInventoryTotalQuantity,
		sumTotalPrice,
		sumMaxHandlingUnits,
		requiredSkills: Array.from(requiredSkills),
		servicePlanNames: Array.from(servicePlanNames).toString(),
		minReservedUntil,
		minScheduledAt
	};
};

const convertCollapseLinkedTasksToTask = (collapseLinkTasks: CollapseLinkedTasksData): Task => {
	const firstTask: Task = cloneDeep(collapseLinkTasks.tasks[0]);
	const {
		minRank,
		maxPreparationReadyForPickupTimePlanned,
		maxFirstScheduledArrival,
		minNoEarlierThan,
		maxNoLaterThan,
		maxEtl,
		minEta,
		minWayPointScheduledAt,
		sumMaxTotalWeight,
		sumInventoryTotalQuantity,
		sumTotalPrice,
		sumMaxHandlingUnits,
		requiredSkills,
		servicePlanNames,
		maxCreatedAt,
		minReservedUntil,
		minScheduledAt,
		minFirstAttemptNoEarlierThan
	} = processTasks(collapseLinkTasks.tasks);

	const lastWayPoint = getLastWayPoint(firstTask);

	lastWayPoint.etos = maxEtl && minEta ? (maxEtl - minEta) / 1000 : undefined;

	lastWayPoint.first_attempt_promise_no_earlier_than = minFirstAttemptNoEarlierThan
		? new Date(minFirstAttemptNoEarlierThan).toISOString()
		: undefined;

	lastWayPoint.eta = minEta ? new Date(minEta).toISOString() : undefined;
	lastWayPoint.etl = maxEtl ? new Date(maxEtl).toISOString() : undefined;
	lastWayPoint.no_later_than = maxNoLaterThan ? new Date(maxNoLaterThan).toISOString() : undefined;
	lastWayPoint.no_earlier_than = minNoEarlierThan ? new Date(minNoEarlierThan).toISOString() : undefined;
	lastWayPoint.scheduled_at = minWayPointScheduledAt ? new Date(minWayPointScheduledAt).toISOString() : undefined;
	lastWayPoint.scheduled_arrival = maxFirstScheduledArrival
		? new Date(maxFirstScheduledArrival).toISOString()
		: undefined;

	const teamId = firstTask.team_ids[0];
	const activeWayPoint = firstTask.active_way_point_id
		? firstTask.way_points.find(task => task.id === firstTask.active_way_point_id)
		: null;

	return {
		...firstTask,
		id: collapseLinkTasks.id,
		external_id: 'COLLAPSE_LINKED_TASKS.MULTI_PICKUP',
		team_ids: [teamId],
		way_points: firstTask.way_points,
		preparation_ready_for_pickup_time_planned: maxPreparationReadyForPickupTimePlanned
			? new Date(maxPreparationReadyForPickupTimePlanned).toISOString()
			: null,
		total_weight: sumMaxTotalWeight,
		reserved_until: minReservedUntil ? new Date(minReservedUntil).toISOString() : null,
		created_at: maxCreatedAt ? new Date(maxCreatedAt).toISOString() : null,
		rank: minRank !== 0 ? minRank : null,
		scheduled_at: minScheduledAt ? new Date(minScheduledAt).toISOString() : null,
		service_plan: { ...firstTask.service_plan, name: servicePlanNames },
		total_price: sumTotalPrice !== 0 ? sumTotalPrice : null,
		aggregations: {
			...firstTask.aggregations,
			inventory_total_quantity: sumInventoryTotalQuantity,
			max_weight: sumMaxTotalWeight,
			max_handling_units: sumMaxHandlingUnits
		},
		required_skills: requiredSkills,
		priority: firstTask.priority,
		active_way_point_id: activeWayPoint?.id
	};
};

const getPriorities = (tasks: Task[]) => {
	const minPriority = Math.min(...tasks.map(task => task.priority));
	const maxPriority = Math.max(...tasks.map(task => task.priority));
	return `${minPriority}-${maxPriority}`;
};

const generateCollapseLinkedTasksData = (lastTask: Task, task: Task, customerId: number): CollapseLinkedTasksData => {
	const tasks = [lastTask, task];
	const tagIds = Array.from(new Set(tasks.map(task => task.tag_id)));

	return {
		id: lastTask.id * -1,
		customer_id: customerId,
		tasks: tasks,
		run_id: lastTask.run_id,
		tag_ids: tagIds,
		priorities: getPriorities(tasks)
	};
};

const getCustomerId = (task: Task, collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>) => {
	if (collapseLinkTasksMap.has(task.id)) {
		return collapseLinkTasksMap.get(task.id).customer_id;
	}
	return getLastWayPoint(task).customer_id;
};

const isValidToCollapse = (
	lastCustomerId: number,
	task: Task,
	collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>
) => {
	const currentCustomerId = getCustomerId(task, collapseLinkTasksMap);
	return (
		lastCustomerId === currentCustomerId &&
		getLastWayPoint(task).eta &&
		getLastWayPoint(task).pickup_dropoff_option === PickupDropoffOption.PICKUP &&
		task.status !== TaskStatus.Done &&
		task.status !== TaskStatus.Cancelled
	);
};

const sortTasksByPriority = (tasks: Task[]) => {
	return tasks.sort((a, b) => {
		if (a.priority < b.priority) {
			return -1;
		} else if (a.priority > b.priority) {
			return 1;
		}

		return 0;
	});
};

const reOrderTasksByCustomer = (
	runTasks: Task[],
	collapseLinkTasksMap: Map<number, CollapseLinkedTasksData>
): (Task | CollapseLinkedTasksData)[] => {
	if (!runTasks.length) {
		return [];
	}

	const sortedRunTasks = sortTasksByPriority(runTasks);

	const firstTask = sortedRunTasks[0];
	let lastCustomerId = getCustomerId(firstTask, collapseLinkTasksMap);
	const newTasksOrder: (Task | CollapseLinkedTasksData)[] = [firstTask];

	sortedRunTasks.slice(1).forEach(task => {
		const currentCustomerId = getCustomerId(task, collapseLinkTasksMap);

		if (isValidToCollapse(lastCustomerId, task, collapseLinkTasksMap)) {
			const lastTaskIsCollapsedTask = Array.isArray(
				(newTasksOrder[newTasksOrder.length - 1] as CollapseLinkedTasksData).tasks
			);

			if (lastTaskIsCollapsedTask) {
				const collapseData = newTasksOrder[newTasksOrder.length - 1] as CollapseLinkedTasksData;
				collapseData.tasks.push(task);
				collapseData.priorities = collapseLinkedTasksGenerator.getPriorities(collapseData.tasks);
			} else {
				const lastTask: Task = newTasksOrder[newTasksOrder.length - 1] as Task;
				if (isValidToCollapse(lastCustomerId, lastTask, collapseLinkTasksMap)) {
					newTasksOrder.pop();
					const collapseLinkTasks = collapseLinkedTasksGenerator.generateCollapseLinkedTasksData(
						lastTask,
						task,
						currentCustomerId
					);
					newTasksOrder.push(collapseLinkTasks);
				} else {
					newTasksOrder.push(task);
				}
			}
		} else {
			lastCustomerId = getLastWayPoint(task).eta ? currentCustomerId : null;
			newTasksOrder.push(task);
		}
	});

	return newTasksOrder;
};

export const collapseLinkedTasksGenerator = {
	convertCollapseLinkedTasksToTask,
	generateCollapseLinkedTasksData,
	getPriorities,
	reOrderTasksByCustomer,
	sortTasksByPriority
};
