import React, { useEffect, useRef, useState } from 'react';

import { Collapse, Modal } from '@bringg/react-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
	DeliveryBlock as BringgDeliveryBlock,
	DeliveryBlockResource,
	HourFormat,
	ParkingSpot,
	PrivilegeTypes,
	RecurringTypes,
	ServiceArea,
	TeamConfiguration
} from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { DeliveryBlockOptionsWithResources } from '@bringg/dashboard-sdk/dist/DeliveryBlocks/DeliveryBlocks.consts';
import { dateUtils } from '@bringg-frontend/utils';
import { cloneDeep as _cloneDeep } from 'lodash';

import { ModalHeader } from './modal-header/modal-header';
import { DeliveryBlockModalFooter } from './modal-footer/delivery-block-modal-footer';
import { RecurrencePanel } from './recurrence/recurrence-panel';
import { BreaksPanel } from './breaks/breaks-panel';
import { DeliveryBlockTime } from './delivery-block-time/delivery-block-time';
import { useStores } from '../../../recipes';
import DeliveryBlock from '../stores/domain-objects/delivery-block';
import deliveryBlockApi from '../api/delivery-block-api';
import RecurringOptionsModal, { RecurringOptions } from '../modal/recurring-options-modal/recurring-options-modal';
import { ApplyRecurringOption, CancelRecurringOption } from '../modal/delivery-block-modal';
import notification from '../../../services/notification';
import { DoubleBookingModal } from './double-booking-modal/double-booking-modal';
import { useDeliveryBlocksStore, useHasAccess } from 'bringg-web';
import { RechargesPanel } from 'bringg-web/features/delivery-blocks-v2/new-modal/recharge/recharge-panel/recharges-panel';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { deliveryBlockBaseEditor } from 'bringg-web/features/delivery-blocks-v2/services/delivery-block-base-editor-service';
import { breakEditor } from 'bringg-web/features/delivery-blocks-v2/services/break-editor-service';
import { rechargeEditor } from 'bringg-web/features/delivery-blocks-v2/services/recharge-editor-service';

import './delivery-block-modal.scss';

export interface ResourcesPanelProps {
	deliveryBlockResources: DeliveryBlockResource[];
	deliveryBlockCapacity: number;
	deliveryBlockTimeRange: { startTime: string; endTime: string };
	teamId: number;
	onResourcesChange: (resources: DeliveryBlockResource[]) => void;
	onCapacityChange: (capacity: number) => void;
	onUnavailableSelectedDriversChange: (unavailableDriversCount: number) => void;
	onUnavailableSelectedVehiclesChange: (unavailableVehiclesCount: number) => void;
	onUnavailableSelectedTrailersChange: (unavailableTrailersCount: number) => void;
	parkingSpots: ParkingSpot[];
	deliveryBlockId?: number;
	props?: any;
	serviceAreas: ServiceArea[];
}

export interface Props {
	visible: boolean;
	onClose: () => void;
	deliveryBlock: Partial<DeliveryBlock>;
	timezone: string;
	parkingSpots: ParkingSpot[];
	serviceAreas: ServiceArea[];
	ResourcesPanel: React.ComponentType<ResourcesPanelProps>;
}

export const ONE_HOUR = 1;
export const ONE_DAY = 1;
export const DEFAULT_DIFFERENCE_BETWEEN_DATES_IN_HOURS = 8;

export enum PANEL_KEYS {
	RecurrencePanel = 'RecurrencePanel',
	BreaksPanel = 'BreaksPanel',
	ResourcesPanel = 'ResourcesPanel',
	RechargePanel = 'RechargePanel',
	TeamsPanel = 'TeamsPanel'
}

export const NewDeliveryBlockModal = ({
	visible,
	onClose,
	deliveryBlock,
	timezone,
	parkingSpots,
	serviceAreas,
	ResourcesPanel
}: Props) => {
	const { merchantConfigurationsStore, teamConfiguration: teamConfigurationApi } = useStores();
	const { deliveryBlocksStore } = useDeliveryBlocksStore();
	const use12Hours = merchantConfigurationsStore.hourFormat !== HourFormat.TwentyFourHours;
	const { allowMultiDayOptimization, endOfWeekDay, initialUnloadingTimeMinutes, initialLoadingTimeMinutes } =
		merchantConfigurationsStore;
	const format = dateUtils.getMerchantTimeFormat(merchantConfigurationsStore.hourFormat);

	const applyRecurringOption = useRef<ApplyRecurringOption>(null);
	const cancelRecurringOption = useRef<CancelRecurringOption>(null);
	const [isRecurringModalVisible, setIsRecurringModalVisible] = useState<boolean>(false);
	const [isFooterEnabled, setIsFooterEnabled] = useState<boolean>(true);
	const [unavailableSelectedDriversCount, setUnavailableSelectedDriversCount] = useState<number>();
	const [unavailableSelectedVehiclesCount, setUnavailableSelectedVehiclesCount] = useState<number>();
	const [unavailableSelectedTrailersCount, setUnavailableSelectedTrailersCount] = useState<number>();
	const [isDoubleBookingModalVisible, setIsDoubleBookingModalVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState({ delete: false, ok: false });
	const [rechargeDuration, setRechargeDuration] = useState<number>(
		initialLoadingTimeMinutes + initialUnloadingTimeMinutes || 0
	);
	const [deliveryBlockEditable, setDeliveryBlockEditable] = useState<Partial<DeliveryBlock>>(() => {
		const defaultStartOfBlock = moment().tz(timezone).startOf('day').add('8', 'hours');
		const copyDeliveryBlock = _cloneDeep(deliveryBlock) as DeliveryBlock;
		copyDeliveryBlock.start_time = copyDeliveryBlock.start_time ?? defaultStartOfBlock.toISOString();
		copyDeliveryBlock.end_time =
			copyDeliveryBlock.end_time ??
			defaultStartOfBlock.clone().add(DEFAULT_DIFFERENCE_BETWEEN_DATES_IN_HOURS, 'hours').toISOString();
		copyDeliveryBlock.ical = copyDeliveryBlock.ical ?? '';
		copyDeliveryBlock.delivery_block_breaks = copyDeliveryBlock.delivery_block_breaks ?? [];
		copyDeliveryBlock.delivery_block_resources = copyDeliveryBlock.delivery_block_resources ?? [];
		return copyDeliveryBlock;
	});
	const editAllowed = useHasAccess(PrivilegeTypes.EDIT_DELIVERY_BLOCK);

	const updateDeliveryBlock = <T,>(key: string, value: T) => {
		setDeliveryBlockEditable(prev => ({ ...prev, [key]: value }));
	};

	const { onIcalChange, onChangeName, onChangeDescription, onCapacityChange, onResourcesChange } =
		deliveryBlockBaseEditor.init(deliveryBlockEditable, updateDeliveryBlock);
	const { onAddBreak, onDeleteBreak, onUpdateBreak, getUpdatedBreaksDates } = breakEditor.init(
		deliveryBlockEditable,
		updateDeliveryBlock
	);
	const { onAddRecharge, onRechargesChange } = rechargeEditor.init(deliveryBlockEditable, updateDeliveryBlock);

	const { t } = useTranslation();

	useEffect(() => {
		const calculateRechargeDuration = async () => {
			const teamConfiguration: TeamConfiguration = await teamConfigurationApi.get(deliveryBlock.team_id);
			const loadingTime = teamConfiguration.initial_loading_time_minutes;
			const unloadingTime = teamConfiguration.initial_unloading_time_minutes;
			const rechargeDurationByTeamConfig = loadingTime + unloadingTime || 0;
			if (rechargeDurationByTeamConfig !== 0) {
				setRechargeDuration(rechargeDurationByTeamConfig);
			}
		};
		calculateRechargeDuration();
	}, []);

	const enableRecharge = useHasFeatureFlag('enable_reload');
	const isRechargeEnabled = enableRecharge && rechargeDuration > 0;

	const recurringOptionModalPromise = async (): Promise<RecurringOptions> =>
		new Promise((resolve: ApplyRecurringOption, reject: CancelRecurringOption) => {
			applyRecurringOption.current = resolve;
			cancelRecurringOption.current = reject;
		});

	const getRecurringOption = async () => {
		setIsRecurringModalVisible(true);
		return recurringOptionModalPromise();
	};

	const isInEditMode = !!deliveryBlock.id;

	const getBasePayload = () => {
		return {
			team_id: deliveryBlockEditable.team_id,
			start_time: deliveryBlockEditable.start_time,
			end_time: deliveryBlockEditable.end_time,
			original_capacity: deliveryBlockEditable.original_capacity,
			name: deliveryBlockEditable.name,
			break_start_time: null,
			break_end_time: null,
			description: deliveryBlockEditable.description,
			delivery_block_breaks: deliveryBlockEditable.delivery_block_breaks,
			delivery_block_resources: deliveryBlockEditable.delivery_block_resources,
			delivery_block_recharges: deliveryBlockEditable.delivery_block_recharges
		};
	};

	const getValidResources = (resources: DeliveryBlockResource[]) => {
		return resources.filter(resource => !!resource.user_id || !!resource.vehicle_id);
	};

	const onCancel = () => {
		onClose();
	};

	const fetchWeek = async (startTime: string, endTime: string) => {
		const startOfWeek = moment(startTime).clone().startOf('week').unix();
		const endOfWeek = moment(endTime).clone().endOf('week').unix();
		await deliveryBlocksStore.fetch(deliveryBlock.team_id, startOfWeek, endOfWeek);
	};

	const onDeleteDeliveryBlock = async () => {
		setIsFooterEnabled(false);
		setLoading(prevState => ({ ...prevState, delete: true }));
		let recurring: RecurringOptions;
		if (deliveryBlockEditable.ical !== '') {
			setIsRecurringModalVisible(true);
			recurring = await getRecurringOption();
		} else {
			recurring = null;
		}
		try {
			const success = await deliveryBlockApi.deleteDeliveryBlock(
				deliveryBlockEditable.id,
				recurring as RecurringTypes
			);
			if (success) {
				notification.success(t('DELIVERY_BLOCKS.DELETED_SUCCESSFULLY'));

				deliveryBlocksStore.remove(deliveryBlockEditable.id);
				if (recurring) {
					await fetchWeek(deliveryBlockEditable.start_time, deliveryBlockEditable.end_time);
				}
				onClose();
			} else {
				notification.error(t('DELIVERY_BLOCKS.FAILED_TO_DELETE'));
			}
		} catch (e) {
			notification.error(t('DELIVERY_BLOCKS.FAILED_TO_DELETE'));
		} finally {
			setLoading(prevState => ({ ...prevState, delete: false }));
			setIsFooterEnabled(true);
		}
	};

	const onEditDeliveryBlock = async (): Promise<BringgDeliveryBlock> => {
		let recurring: RecurringOptions;
		if (deliveryBlockEditable.ical !== '') {
			setIsRecurringModalVisible(true);
			recurring = await getRecurringOption();
		} else {
			recurring = null;
		}

		const payload = {
			...getBasePayload(),
			id: deliveryBlockEditable.id,
			ical: deliveryBlockEditable.ical,
			recurring: recurring as RecurringTypes,
			delivery_block_resources: getValidResources(deliveryBlockEditable.delivery_block_resources),
			delivery_block_recharges: deliveryBlockEditable.delivery_block_recharges
		} as DeliveryBlockOptionsWithResources;

		try {
			const result = await deliveryBlockApi.editDeliveryBlock(payload);
			notification.success(t('DELIVERY_BLOCKS.UPDATED_SUCCESSFULLY'));
			return result;
		} catch (e) {
			notification.error(t('DELIVERY_BLOCKS.FAILED_TO_CREATE_OR_UPDATE'));
			return null;
		}
	};

	const onCreateDeliveryBlock = async (): Promise<BringgDeliveryBlock> => {
		const ical = deliveryBlockEditable.ical === '' ? null : deliveryBlockEditable.ical;
		const payload = { ...getBasePayload() } as DeliveryBlockOptionsWithResources;
		if (ical) {
			Object.assign(payload, { ical });
		}

		payload.delivery_block_resources = getValidResources(payload.delivery_block_resources);

		try {
			const result = await deliveryBlockApi.createDeliveryBlock(payload);
			notification.success(t('DELIVERY_BLOCKS.CREATED_SUCCESSFULLY'));
			return result;
		} catch (e) {
			notification.error(t('DELIVERY_BLOCKS.FAILED_TO_CREATE_OR_UPDATE'));
			return null;
		}
	};

	const containsUnavailableResources = () => {
		return (
			unavailableSelectedDriversCount > 0 ||
			unavailableSelectedVehiclesCount > 0 ||
			unavailableSelectedTrailersCount > 0
		);
	};

	const createOrUpdateDeliveryBlock = async () => {
		let result;
		try {
			if (isInEditMode) {
				result = await onEditDeliveryBlock();
			} else {
				result = await onCreateDeliveryBlock();
			}
			if (result) {
				const deliveryBlock = new DeliveryBlock(deliveryBlocksStore, result);
				deliveryBlocksStore.set(deliveryBlock);
				if (deliveryBlock.isRecurring) {
					await fetchWeek(deliveryBlock.start_time, deliveryBlock.end_time);
				}
				onClose();
			}
		} catch (e) {
			console.error('error: create or update delivery block', e);
		} finally {
			setLoading(prevState => ({ ...prevState, ok: false }));
		}
	};

	const onOk = async () => {
		setIsFooterEnabled(false);
		setLoading(prevState => ({ ...prevState, ok: true }));

		if (containsUnavailableResources()) {
			setIsDoubleBookingModalVisible(true);
			return;
		}
		await createOrUpdateDeliveryBlock();
	};

	const onRecurringOptionApply = (recurringOption: RecurringOptions) => {
		setIsRecurringModalVisible(false);
		applyRecurringOption.current(recurringOption);
	};

	const onRecurringOptionCancel = () => {
		setIsRecurringModalVisible(false);
		setLoading(prevState => ({ ...prevState, ok: false }));
		setIsFooterEnabled(true);
	};

	const onDoubleBookingApplied = async () => {
		setIsDoubleBookingModalVisible(false);
		setIsFooterEnabled(true);
		await createOrUpdateDeliveryBlock();
	};

	const onDoubleBookingCanceled = () => {
		setIsDoubleBookingModalVisible(false);
		setIsFooterEnabled(true);
		setLoading(prevState => ({ ...prevState, ok: false }));
	};

	const updateDeliveryBlockTimes = (startTime: string, endTime: string) => {
		setDeliveryBlockEditable(prev => {
			return {
				...prev,
				start_time: startTime,
				end_time: endTime,
				delivery_block_breaks: getUpdatedBreaksDates(startTime, timezone)
			};
		});
	};

	return (
		<div>
			<Modal
				maskClosable={false}
				width={1000}
				open={visible}
				wrapClassName={classNames('delivery-block-modal-wrapper', {
					'with-areas': !!serviceAreas.length
				})}
				title={
					<ModalHeader
						deliveryBlockEditable={deliveryBlockEditable}
						onChangeName={onChangeName}
						onChangeDescription={onChangeDescription}
						timezone={timezone}
					/>
				}
				onCancel={onCancel}
				footer={
					<DeliveryBlockModalFooter
						onCancel={onCancel}
						onOk={onOk}
						onDelete={onDeleteDeliveryBlock}
						isInEditMode={isInEditMode}
						isEnabled={editAllowed && isFooterEnabled}
						loading={loading}
					/>
				}
			>
				<DeliveryBlockTime
					deliveryBlockStartTime={deliveryBlockEditable.start_time}
					deliveryBlockEndTime={deliveryBlockEditable.end_time}
					updateDeliveryBlockTimes={updateDeliveryBlockTimes}
					timezone={timezone}
					use12Hours={use12Hours}
					format={format}
					allowMultiDayOptimization={allowMultiDayOptimization}
				/>

				<Collapse
					bordered={false}
					className="delivery-block-collapse"
					expandIcon={({ isActive }) => (
						<div className="collapse-chevron">
							<BringgIcon iconName={isActive ? BringgFontIcons.Chevron : BringgFontIcons.ChevronRight} />
						</div>
					)}
					expandIconPosition="end"
				>
					<BreaksPanel
						key={PANEL_KEYS.BreaksPanel}
						deliveryBlockTimes={{
							startTime: deliveryBlockEditable.start_time,
							endTime: deliveryBlockEditable.end_time
						}}
						deliveryBlockBreaks={deliveryBlockEditable.delivery_block_breaks}
						timezone={timezone}
						use12Hours={use12Hours}
						format={format}
						onAddBreak={onAddBreak}
						onDeleteBreak={onDeleteBreak}
						onUpdateBreak={onUpdateBreak}
						allowMultiDayOptimization={allowMultiDayOptimization}
					/>
					{isRechargeEnabled && (
						<RechargesPanel
							key={PANEL_KEYS.RechargePanel}
							deliveryBlockRecharges={deliveryBlockEditable.delivery_block_recharges ?? []}
							deliveryBlockBreaks={deliveryBlockEditable.delivery_block_breaks ?? []}
							rechargeDuration={rechargeDuration}
							timezone={timezone}
							use12Hours={use12Hours}
							format={format}
							deliveryBlockTimes={{
								startTime: deliveryBlockEditable.start_time,
								endTime: deliveryBlockEditable.end_time
							}}
							onChangeRecharge={onRechargesChange}
							onAddRecharge={onAddRecharge}
						/>
					)}
					<RecurrencePanel
						key={PANEL_KEYS.RecurrencePanel}
						ical={deliveryBlockEditable.ical}
						onIcalChange={onIcalChange}
						timezone={timezone}
						endOfWeekDay={endOfWeekDay}
						deliveryBlockDate={moment(deliveryBlockEditable.start_time).date()}
						shouldBeDisabled={isInEditMode}
						hideEndsAt={false}
					/>
					<ResourcesPanel
						key={PANEL_KEYS.ResourcesPanel}
						deliveryBlockResources={deliveryBlockEditable.delivery_block_resources ?? []}
						deliveryBlockCapacity={deliveryBlockEditable.original_capacity}
						deliveryBlockTimeRange={{
							startTime: moment(deliveryBlockEditable.start_time).tz(timezone).toISOString(),
							endTime: moment(deliveryBlockEditable.end_time).tz(timezone).toISOString()
						}}
						deliveryBlockId={deliveryBlockEditable.id}
						serviceAreas={serviceAreas}
						teamId={deliveryBlock.team_id}
						parkingSpots={parkingSpots}
						onResourcesChange={onResourcesChange}
						onCapacityChange={onCapacityChange}
						onUnavailableSelectedDriversChange={setUnavailableSelectedDriversCount}
						onUnavailableSelectedVehiclesChange={setUnavailableSelectedVehiclesCount}
						onUnavailableSelectedTrailersChange={setUnavailableSelectedTrailersCount}
					/>
				</Collapse>
			</Modal>
			<RecurringOptionsModal
				onApply={onRecurringOptionApply}
				onCancel={onRecurringOptionCancel}
				visible={isRecurringModalVisible}
			/>
			<DoubleBookingModal
				onApply={onDoubleBookingApplied}
				onCancel={onDoubleBookingCanceled}
				visible={isDoubleBookingModalVisible}
			/>
		</div>
	);
};
