import { action, computed, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import { ActionType, CancelTaskActionMetadata } from '@bringg/types';

import { ActionsRepo, ActionBaseStore } from './internal';
import { ActionFamilyType, ClientCalculateAvailability } from '../utils/types';

export const VALID_GAP = 16;

class CalculateAvailabilityActionStore extends ActionBaseStore {
	type = ActionFamilyType.CALCULATE_AVAILABILITY_STATE;
	actionType = ActionType.CALCULATE_AVAILABILITY_STATE;
	fromDays: number;
	toDays: number;

	constructor(initAction: ClientCalculateAvailability, actionsRepo: ActionsRepo) {
		super(actionsRepo);

		makeObservable(this, {
			fromDays: observable,
			toDays: observable,
			setFromDays: action,
			setToDays: action,
			title: computed,
			isValid: computed,
			mappedToServer: computed,
			hasValueError: computed,
			hasGapError: computed
		});

		if (initAction.data?.from_days_ahead != null) {
			this.fromDays = initAction.data?.from_days_ahead;
		}
		if (initAction.data?.to_days_ahead != null) {
			this.toDays = initAction.data?.to_days_ahead;
		}
	}

	setFromDays = date => {
		this.fromDays = date;
	};

	setToDays = date => {
		this.toDays = date;
	};

	get mappedToServer() {
		return {
			action_type: this.actionType,
			data: {
				from_days_ahead: this.fromDays,
				to_days_ahead: this.toDays
			}
		};
	}

	get hasGapError(): boolean {
		return this.toDays - this.fromDays >= VALID_GAP;
	}

	get hasValueError(): boolean {
		return this.fromDays > this.toDays;
	}

	get isValid(): boolean {
		return this.fromDays > -1 && this.toDays > -1 && !this.hasValueError && !this.hasGapError;
	}

	get title(): string {
		const titleKey = (this.actionData as CancelTaskActionMetadata).translation_string;

		return i18next.t(titleKey);
	}
}

export default CalculateAvailabilityActionStore;
