import { Cluster, MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';

import { IdType } from '../../types';
import ClusterRenderer from './cluster-renderer';
import { getDataForMarker } from './markers-to-marker-data';

interface ClusterInitOptions {
	maxZoom: number;
}

export const getCluster = (
	map: google.maps.Map,
	markerInstanceMap: Map<IdType, google.maps.Marker>,
	options: ClusterInitOptions,
	onClusterClickHandler: (event: google.maps.MapMouseEvent, cluster: Cluster) => void
) => {
	const markers: google.maps.Marker[] = [];

	markerInstanceMap.forEach(markerInstance => {
		const data = getDataForMarker(markerInstance);
		if (data && data.shouldCluster) {
			markers.push(markerInstance);
		}
	});

	return new MarkerClusterer({
		map,
		markers,
		algorithm: new SuperClusterAlgorithm({ maxZoom: options.maxZoom }),
		renderer: new ClusterRenderer(),
		onClusterClick: onClusterClickHandler
	});
};
