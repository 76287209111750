import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';
import { pick } from 'lodash';
import { Drawer, Notification, Radio } from '@bringg/react-components';
import { observer } from 'mobx-react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Portal } from '@bringg-frontend/bringg-web-infra';
import { PrivilegeTypes } from '@bringg/types';

import { ServiceAreaMap } from 'bringg-web/features/service-area/map';
import { useStores } from 'bringg-web/recipes';
import { AddServiceAreaButton } from 'bringg-web/features/service-area/add-button';
import { ServiceAreaForm } from 'bringg-web/features/service-area/form/form';
import { ServiceAreaTable } from './table';
import { ServiceAreaFilterBar } from 'bringg-web/features/service-area/form/filter-bar';
import { enclosePolygon } from 'bringg-web/features/service-area/utils/enclose-polygon';
import ServiceAreaUploadModal from 'bringg-web/features/service-area/upload-modal';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { SERVICE_AREA_LAT_LNG_FF } from 'bringg-web/consts';
import ResourceAsyncOperationStatus from 'bringg-web/features/resources/resource-async-operation-status';
import { DecoratedServiceArea } from 'bringg-web/stores/service-areas-view/service-areas-view-store';
import { useServiceAreaTranslation } from './translations';
import { useHasAccess } from 'bringg-web/utils/privileges';

import './service-area.scss';

export enum VIEW_MODE {
	MAP = 'map',
	TABLE = 'table'
}

export const ServiceArea = observer(({ teamId = null, readonly = false }: { teamId?: number; readonly?: boolean }) => {
	const { search } = useLocation();
	const { path } = useRouteMatch();
	const history = useHistory();
	const serviceAreaId = useMemo(() => new URLSearchParams(search).get('id'), [search]);

	const translations = useServiceAreaTranslation();
	const { serviceAreasStore, serviceAreaViewStore, merchantStore, teamsStore } = useStores();
	const [view, setView] = useState<VIEW_MODE>(VIEW_MODE.MAP);
	const [resourceId, setResourceId] = useState<string>(null);
	const [fileUploadOpen, setFileUploadOpen] = useState(false);
	const latLngFF = useHasFeatureFlag(SERVICE_AREA_LAT_LNG_FF);
	const newDispatcherSideMenuItemsFF = useHasFeatureFlag('dispatcher_new_side_menu_items');
	const canEditServiceArea = newDispatcherSideMenuItemsFF
		? useHasAccess(PrivilegeTypes.ALLOW_DISPATCHER_EDIT_SERVICE_AREA)
		: !readonly;

	const createEmptyServiceArea = useCallback(() => ({ team_ids: teamId ? [teamId] : [] }), [teamId]);

	useEffect(() => {
		void serviceAreaViewStore.init(serviceAreasStore, latLngFF);
		void merchantStore.fetch();
		void teamsStore.fetchAll();
	}, [teamsStore, serviceAreasStore, merchantStore, serviceAreaViewStore, latLngFF]);

	const allFetched = useMemo(
		() => serviceAreasStore.isTeamsFetched && serviceAreasStore.isFetched,
		[serviceAreasStore.isTeamsFetched, serviceAreasStore.isFetched]
	);

	useEffect(() => {
		if (!allFetched || !serviceAreaId) {
			return;
		}

		if (serviceAreaId === 'new') {
			serviceAreaViewStore.focusServiceArea(createEmptyServiceArea());
			return;
		}

		const serviceArea = serviceAreaViewStore.decorated.find(sa => sa.id === Number(serviceAreaId));

		if (serviceArea) {
			serviceAreaViewStore.focusServiceArea(serviceArea);
		}
		// run once all fetched
	}, [allFetched]);

	useEffect(() => {
		if (!allFetched) {
			return;
		}

		const focused = serviceAreaViewStore.focusedServiceArea;
		const id = focused?.id || 'new';
		history.replace(!focused ? path : `${path}?id=${id}`);
	}, [serviceAreaViewStore.focusedServiceArea, allFetched]);

	const exitFromEditMode = useCallback(() => {
		serviceAreaViewStore.focusServiceArea(null);
	}, [serviceAreaViewStore]);

	const onSubmit = async (serviceArea, teamsServiceArea) => {
		try {
			await serviceAreaViewStore.save(serviceArea, teamsServiceArea, { teamId });
			serviceAreaViewStore.focusServiceArea(null);
		} catch {
			return Notification.error(translations.failedToCreate);
		}

		Notification.success(serviceArea.id ? translations.successfullyUpdated : translations.successfullyCreated);

		exitFromEditMode();
	};

	useEffect(() => {
		serviceAreaViewStore.filterBy({ byTeams: teamId ? [teamId] : null });
	}, [teamId, serviceAreaViewStore]);

	const onPolygonComplete = (serviceArea: Partial<DecoratedServiceArea>, googlePolygon: google.maps.Polygon) => {
		const polygon = enclosePolygon(
			googlePolygon
				.getPath()
				.getArray()
				.map(obj => (latLngFF ? [obj.lat(), obj.lng()] : [obj.lng(), obj.lat()]))
		);

		serviceAreaViewStore.focusServiceArea({ ...serviceArea, polygon });
		googlePolygon.setMap(null);
	};

	const onPolygonCreate = useCallback(
		(googlePolygon: google.maps.Polygon) => {
			onPolygonComplete(createEmptyServiceArea(), googlePolygon);
		},
		[createEmptyServiceArea]
	);

	const onPolygonEdit = useCallback(
		(googlePolygon: google.maps.Polygon) => {
			onPolygonComplete(serviceAreaViewStore.focusedServiceArea, googlePolygon);
		},
		[serviceAreaViewStore.focusedServiceArea]
	);

	const onEdit = useCallback(
		serviceArea => {
			serviceAreaViewStore.focusServiceArea(serviceArea);
			setView(VIEW_MODE.MAP);
		},
		[serviceAreaViewStore]
	);

	const mapCenter = useMemo(() => {
		if (teamId) {
			return teamsStore.teams.get(teamId)?.lat ? pick(teamsStore.teams.get(teamId), ['lat', 'lng']) : null;
		}

		return merchantStore.merchant?.lat ? pick(merchantStore.merchant, ['lat', 'lng']) : null;
	}, [merchantStore.merchant, teamsStore.teams, teamId]);

	const onAdd = useCallback(() => {
		serviceAreaViewStore.focusServiceArea(createEmptyServiceArea());
		setView(VIEW_MODE.MAP);
	}, [serviceAreaViewStore, createEmptyServiceArea]);

	const onUploaded = useCallback(() => {
		void serviceAreasStore.forceFetchAll();
	}, [serviceAreasStore]);

	const onUploadClosed = useCallback(() => {
		setResourceId(null);
	}, []);

	return (
		<div className={classnames('service-area-page', { 'team-page-offset': teamId && serviceAreaId })}>
			<Drawer
				open={Boolean(serviceAreaId)}
				width="calc(var(--merchant-navigation-container-width) + var(--navbar-left-side-width))"
				mask={false}
				closable={false}
				placement="left"
			>
				<ServiceAreaForm showTeamsSection={!teamId} onSubmit={onSubmit} onClose={exitFromEditMode} />
			</Drawer>

			<div className="header">
				<h2 className="title">{translations.title}</h2>
				{canEditServiceArea ? (
					<AddServiceAreaButton
						showBulkUpload={!teamId}
						onBulkUpload={() => setFileUploadOpen(true)}
						onAdd={onAdd}
					/>
				) : null}
			</div>
			<div className="main">
				<ServiceAreaFilterBar teamId={teamId} />
				<div className="map-tools-description">
					{view === VIEW_MODE.MAP && <div>{translations.mapToolsDescription}</div>}
					{view === VIEW_MODE.TABLE && <div />}
					<Radio.Group buttonStyle="solid" value={view} onChange={e => setView(e.target.value)}>
						<Radio.Button value={VIEW_MODE.MAP}>
							<BringgIcon iconName={BringgFontIcons.Map} />
						</Radio.Button>
						<Radio.Button value={VIEW_MODE.TABLE}>
							<BringgIcon iconName={BringgFontIcons.Monitor} />
						</Radio.Button>
					</Radio.Group>
				</div>
				{allFetched && view === VIEW_MODE.MAP && (
					<div className="service-area-map" data-test-id="service-area-map-container">
						<ServiceAreaMap
							teamId={teamId}
							mapCenter={mapCenter}
							readonly={!canEditServiceArea}
							onPolygonCreate={onPolygonCreate}
							onPolygonEdit={onPolygonEdit}
						/>
					</div>
				)}
				{allFetched && view === VIEW_MODE.TABLE && (
					<div data-test-id="service-area-table-container">
						<ServiceAreaTable readonly={!canEditServiceArea} onEdit={onEdit} teamId={teamId} />
					</div>
				)}
			</div>

			<ServiceAreaUploadModal
				open={fileUploadOpen}
				onUploadStarted={result => {
					setFileUploadOpen(false);
					setResourceId(result.resource_id);
				}}
				onCancel={() => setFileUploadOpen(false)}
			/>
			<ResourceAsyncOperationStatus
				minimized
				title={translations.progressTitle}
				translations={translations}
				onUploadFinished={onUploaded}
				resourceId={resourceId}
				onClose={onUploadClosed}
				errorsFileRender={status =>
					status.errors_url && (
						<a href={status.errors_url} target="_blank" rel="noreferrer">
							{translations.downloadErrorsFile}
						</a>
					)
				}
			/>
		</div>
	);
});

const ServiceAreaPortal = () => <Portal element={<ServiceArea />} nodeId="service-area-portal" />;

export default ServiceAreaPortal;
