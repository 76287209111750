import { useEffect, useState } from 'react';

import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';

import { getCluster } from '../utils/cluster';
import { ClusterOptions, ExtendedMarkerData, IdType } from '../../types';
import { getDataForMarker } from '../utils/markers-to-marker-data';

type UseClustersProps = {
	map: google.maps.Map | null;
	markerInstanceMap: Map<IdType, google.maps.Marker>;
	infoWindow: google.maps.InfoWindow;
	clusterOptions: ClusterOptions;
	selectedMarkers: Set<IdType>;
};

const MAX_ZOOM = 20;
const NUMBER_OF_DIGITS = 4;
export const CLUSTER_ID = 'cluster_id';

const getPositionId = (position: google.maps.LatLng) => {
	const lat = position.lat().toFixed(NUMBER_OF_DIGITS);
	const lng = position.lng().toFixed(NUMBER_OF_DIGITS);

	return `${lat}_${lng}`;
};

export const useClusters = ({
	map,
	markerInstanceMap,
	infoWindow,
	clusterOptions,
	selectedMarkers
}: UseClustersProps) => {
	const [markerClusterer, setMarkerClusterer] = useState<MarkerClusterer>();
	const [clusterTooltipCount, setClusterTooltipCount] = useState<number>();
	const [numberOfSelected, setNumberOfSelected] = useState<number>();

	let onClusteringEndListener: google.maps.MapsEventListener;

	const onClusterClick = (event: google.maps.MapMouseEvent, _cluster: Cluster) => {
		// @ts-ignore
		map.systemZoomChange = false;

		infoWindow.close();

		if (!map || !_cluster.markers || !_cluster.markers.length) {
			return;
		}

		const isInMaxZoom = map.getZoom() === MAX_ZOOM - 1;

		if (isInMaxZoom) {
			if (clusterOptions.getClusterTooltip) {
				const markersData = _cluster.markers?.map(marker => getDataForMarker(marker));

				if (markersData) {
					const clusterId = getPositionId(_cluster.position);
					infoWindow.set(CLUSTER_ID, clusterId);
					infoWindow.setPosition(_cluster.position);

					const content = clusterOptions.getClusterTooltip(
						_cluster.markers
							.map((marker: google.maps.Marker) => getDataForMarker(marker))
							.filter(Boolean) as ExtendedMarkerData[]
					);

					infoWindow.setContent(content);
					infoWindow.open(map);

					setClusterTooltipCount(_cluster.markers.length);
				}
			}

			if (clusterOptions.onClusterClick) {
				// @ts-ignore
				const ctrlKey = event.domEvent.metaKey || event.domEvent.ctrlKey;
				clusterOptions.onClusterClick(ctrlKey, _cluster);
			}
		}
		if (_cluster.bounds) {
			map.fitBounds(_cluster.bounds);
		}
	};

	const onClusteringEnd = (_cluster: Cluster) => {
		if (infoWindow.isOpen) {
			const infoWindowClusterId = infoWindow.get(CLUSTER_ID);
			// @ts-ignore
			const clusterWithInfoWindow = _cluster.clusters.find(innerCluster => {
				const innerClusterId = getPositionId(innerCluster.position);
				return innerClusterId === infoWindowClusterId;
			});

			if (clusterWithInfoWindow) {
				const markersData = clusterWithInfoWindow.markers
					.map((marker: google.maps.Marker) => getDataForMarker(marker))
					.filter(Boolean) as ExtendedMarkerData[];

				const numberOfSelectedFromCluster = markersData.filter(m => selectedMarkers.has(m.id)).length;

				if (clusterWithInfoWindow.markers.length <= 1) {
					infoWindow.close();
					infoWindow.set(CLUSTER_ID, null);
				} else if (
					clusterOptions.getClusterTooltip &&
					(clusterWithInfoWindow.markers.length !== clusterTooltipCount ||
						numberOfSelected !== numberOfSelectedFromCluster)
				) {
					const content = clusterOptions.getClusterTooltip(markersData);
					infoWindow.setContent(content);
					setClusterTooltipCount(clusterWithInfoWindow.markers.length);
					setNumberOfSelected(numberOfSelectedFromCluster);
				}
			}
		}
	};

	useEffect(() => {
		if (clusterOptions.cluster && map) {
			markerClusterer?.clearMarkers();

			const clusterInstance = getCluster(map, markerInstanceMap, { maxZoom: MAX_ZOOM }, onClusterClick);
			onClusteringEndListener = google.maps.event.addListener(clusterInstance, 'clusteringend', onClusteringEnd);
			setMarkerClusterer(clusterInstance);
		}
	}, [markerInstanceMap]);

	useEffect(() => {
		return () => {
			infoWindow.set(CLUSTER_ID, null);
			setClusterTooltipCount(-1);
			onClusteringEndListener?.remove();
		};
	}, []);

	return {
		markerClusterer
	};
};
