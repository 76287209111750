import sanitizeHtml from 'sanitize-html-react';
import { allowedTags, allowedAttributes, additionalSelfClosing } from '@bringg/common-utils';
import { getRoot } from 'mobx-easy';
import { hasFeatureFlag } from '@bringg-frontend/global-stores';
import { dateUtils } from '@bringg-frontend/utils';
import moment from 'moment/moment';
import { HourFormat } from '@bringg/types';

import { labelRegex } from 'bringg-web/components/text-template';
import type RootStore from 'bringg-web/stores/root-store';
import { DISABLE_TEMPLATE_SANITIZER_FF } from './consts';
import { SupportedPlaceholders } from './types';

const removeRepeatedEmptyLines = (text: string): string => {
	// Get rid of ZWSP before removing repeated empty lines
	return text.replace(/\u200B/g, ' ').replace(/\n(\s+)?\n(\s+)?\n/g, '\n\n');
};

export const sanitizeHtmlContent = (previewContent: string): string => {
	// sanitize-html doesn't support <!doctype> anyhow at the moment
	// https://github.com/apostrophecms/sanitize-html/issues/131
	const [firstLine, ...restLines] = previewContent.split('\n');
	const hasDoctype = /^<!doctype html>$/i.exec(firstLine.trim());
	const contentToSanitize = (hasDoctype ? restLines : [firstLine, ...restLines]).join('\n');

	const sanitizedContent = sanitizeHtml(contentToSanitize, {
		allowedTags,
		allowedAttributes,
		selfClosing: sanitizeHtml.defaults.selfClosing.concat(additionalSelfClosing)
	});

	const result = removeRepeatedEmptyLines(sanitizedContent);

	return hasDoctype ? `<!doctype html>\n${result}` : result;
};

export const sanitizeHtmlContentByFF = (previewContent: string) => {
	const { currentUser } = getRoot<RootStore>().data.usersStore;
	const isSanitizerDisabled = hasFeatureFlag(currentUser, DISABLE_TEMPLATE_SANITIZER_FF);

	return isSanitizerDisabled ? previewContent : sanitizeHtmlContent(previewContent);
};

export const replacePlaceHoldersWithMockForPreview = (content: string, placeholders: Record<string, string>) => {
	let newContent = content;

	const matches = newContent.matchAll(labelRegex);
	for (const match of matches) {
		// remove `{{`` on the start of match and `}}` on the end
		const placeholderName = match[0].replace(/[{}]/g, '');

		if (placeholders[placeholderName]) {
			newContent = newContent.replace(match[0], placeholders[placeholderName]);
		}
	}

	return newContent;
};

type PlaceholdersConfig = {
	hourFormat: HourFormat;
};
export const getPlaceholdersDummyData = ({ hourFormat }: PlaceholdersConfig): Record<SupportedPlaceholders, string> => {
	const timeFormat = dateUtils.getMerchantTimeFormat(hourFormat, true);

	return {
		[SupportedPlaceholders.Merchant]: 'Galactic Delivery',
		[SupportedPlaceholders.ScheduleLink]: 'https://www.google.com/search?q=schedule',
		[SupportedPlaceholders.ScheduleReadyLink]: 'https://www.google.com/search?q=schedule+ready',
		[SupportedPlaceholders.PickupLink]: 'https://www.google.com/search?q=pickup',
		[SupportedPlaceholders.TrackingLink]: 'https://www.google.com/search?q=tracking',
		[SupportedPlaceholders.CustomerName]: 'Meggie Carter',
		[SupportedPlaceholders.DriverName]: 'John Solo',
		[SupportedPlaceholders.JobDescription]: 'Delivery fast and reliable',
		[SupportedPlaceholders.OrderTitle]: 'Furniture for Meggie',
		[SupportedPlaceholders.OrderId]: 'furniture-delivery-for-meggie',
		[SupportedPlaceholders.WaypointCustomerName]: 'Walt Whitman',
		[SupportedPlaceholders.CustomerFirstName]: 'Meggie',
		[SupportedPlaceholders.DeliveryAddress]: 'Santa Anita 15009, Peru',
		[SupportedPlaceholders.DeliveryTimeWindow]: `${moment().add(30, 'minutes').format(timeFormat)} - ${moment()
			.add(60, 'minute')
			.format(timeFormat)}`,
		[SupportedPlaceholders.TeamDescription]: 'The Best Team',
		[SupportedPlaceholders.NotificationId]: 'cRAMnqEn'
	};
};

export const printOrderDummyData = [
	{
		title: 'Furniture for Meggie',
		user_id: 3791521,
		user: {
			id: 3791521,
			company: {
				id: 1,
				name: 'The Best Company',
				phone: '+448979599',
				tax_id: 'tax id 1',
				address: 'Santa Anita 15009, Peru'
			}
		},
		created_at: '2025-02-19T13:24:50.595Z',
		scheduled_at: '2025-03-31T09:00:00.000Z',
		id: 13,
		address: 'P.I. San Cristóbal, C. Níquel, 6',
		extras: {
			reintento_de_entrega: false,
			es_orden_dividido: false,
			parte_de_orden_dividida: null
		},
		external_id: 'furniture-delivery-for-meggie',
		customer_id: 11,
		total_price: 1500,
		left_to_be_paid: 1300,
		left_to_be_paid_for_delivery: 20,
		service_fee: 23,
		price_before_tax: 1790,
		pre_delivery_tip: 10,
		small_cart_fee: 15,
		delivery_price: 123,
		delivery_cost: 123,
		discount: 10,
		payment_type_name: 'payment_type_name',
		tag_id: 2,
		task_type_id: 2,
		origin_title: '1',
		required_skills: [],
		team_ids: [161442],
		run_id: 100,
		fleet_id: 3,
		total_weight: 3000,
		vehicle_id: 516489,
		vehicle: {
			id: 516489,
			license_plate: 'EU4587'
		},
		updated_at: '2025-02-19T13:29:02.814Z',
		service_plan: {
			id: 2,
			name: 'Precise Hour',
			external_id: 'PRECISE_HOUR',
			merchant_id: 1
		},
		task_notes: [
			{
				title: '19 Feb, 2025 01:24 PM, by unknown',
				note: 'Pick - test pickup notes',
				created_at: '2025-02-19T13:24:50.606Z',
				author_name: 'valladolid',
				id: 12,
				way_point_id: 13,
				type: 'TaskNote',
				external_id: '12',
				task_id: 13
			},
			{
				title: '19 Feb, 2025 01:24 PM, by unknown',
				note: 'Dropoff - test dropoff notes',
				created_at: '2025-02-19T13:24:50.744Z',
				author_name: 'Bringg CustomerB',
				id: 13,
				way_point_id: 14,
				type: 'TaskNote',
				external_id: '13',
				task_id: 14
			}
		],
		way_points: [
			{
				id: 13,
				name: 'Valladolid',
				scheduled_at: '2025-03-31T09:00:00.000Z',
				task_id: 13,
				customer_id: 12,
				address: 'P.I. San Cristóbal, C. Níquel, 6',
				zipcode: '47012',
				position: 2,
				note: null,
				city: ' Valladolid',
				phone: '+34983219600',
				no_later_than: null,
				no_earlier_than: null,
				district: 'Valladolid',
				borough: 'Valladolid',
				state: 'ES',
				pickup_dropoff_option: 0
			},
			{
				id: 14,
				scheduled_at: '2025-03-31T10:02:00.000Z',
				task_id: 14,
				customer_id: 5,
				address: 'Pl. del Olivo, 334',
				zipcode: '47193',
				position: 2,
				note: null,
				city: 'Cistérniga',
				borough: '',
				full_address: 'Pl. del Olivo, 334, , Cistérniga, ES, 47193',
				phone: '+447570610520',
				email: '',
				no_later_than: '2025-03-31T11:00:00.000Z',
				no_earlier_than: '2025-03-31T08:00:00.000Z',
				district: 'Valladolid',
				state: 'ES',
				pickup_dropoff_option: 1,
				name: 'Bringg CustomerB'
			}
		],
		task_inventories: [
			{
				id: 32,
				task_id: 14,
				inventory_id: 13,
				quantity: 0,
				original_quantity: 1,
				merchant_id: 1,
				name: 'Paleta 1',
				way_point_id: 14,
				scan_string: '1739971490-1',
				parent_task_inventory_id: null,
				weight: 1000,
				inventory: {
					id: 13,
					name: 'Paleta 1',
					merchant_id: 1,
					original_quantity: 1,
					scan_string: '1739971490-1',
					weight: 2000,
					description: 'Paleta 2'
				}
			},
			{
				id: 33,
				task_id: 14,
				inventory_id: 13,
				quantity: 0,
				original_quantity: 1,
				merchant_id: 1,
				name: 'Paleta 2',
				way_point_id: 14,
				scan_string: '1739971490-1',
				note: null,
				parent_task_inventory_id: null,
				external_id: '1739971490-1',
				weight: 2000,
				task_inventory_original_id: null,
				inventory: {
					id: 13,
					name: 'Paleta 1',
					merchant_id: 1,
					external_id: '1739971490-1',
					scan_string: '1739971490-1',
					weight: 2000,
					description: 'Paleta 2'
				}
			},
			{
				id: 34,
				task_id: 14,
				inventory_id: 14,
				quantity: 0,
				original_quantity: 1,
				merchant_id: 1,
				name: 'Inventory test 1/3',
				way_point_id: 14,
				scan_string: '1739971490-11',
				parent_task_inventory_id: 32,
				external_id: '1739971490-11',
				weight: null,
				length: 10,
				height: 10,
				width: 10,
				task_inventory_original_id: null,
				inventory: {
					id: 14,
					name: 'Inventory test 1/3',
					image: '/images/product-placeholder.png',
					merchant_id: 1,
					scan_string: '1739971490-11',
					weight: null,
					width: 10,
					height: 10,
					length: 10,
					description: 'test inventory 1/3'
				}
			},
			{
				id: 35,
				task_id: 14,
				inventory_id: 15,
				quantity: 0,
				original_quantity: 1,
				merchant_id: 1,
				name: 'Inventory test 2/3',
				way_point_id: 14,
				scan_string: '1739971490-21',
				parent_task_inventory_id: 33,
				weight: null,
				length: 20,
				height: 20,
				width: 20,
				task_inventory_original_id: null,
				inventory: {
					id: 15,
					name: 'Inventory test 2/3',
					merchant_id: 1,
					scan_string: '1739971490-21',
					weight: null,
					width: 20,
					height: 20,
					length: 20,
					description: 'test inventory 2/3'
				}
			},
			{
				id: 36,
				task_id: 14,
				inventory_id: 16,
				quantity: 0,
				original_quantity: 1,
				merchant_id: 1,
				name: 'Inventory test 3/3',
				way_point_id: 14,
				scan_string: '1739971490-22',
				parent_task_inventory_id: 33,
				external_id: '1739971490-22',
				weight: null,
				length: 30,
				height: 30,
				width: 30,
				task_inventory_original_id: null,
				inventory: {
					id: 16,
					name: 'Inventory test 3/3',
					merchant_id: 1,
					scan_string: '1739971490-22',
					weight: null,
					width: 30,
					height: 30,
					length: 30,
					description: 'test inventory 3/3'
				}
			}
		]
	}
];
