import { useCallback, useEffect, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';

import Page from 'bringg-web/features/order-history-page/components/page/page';
import { useStores } from 'bringg-web/recipes';
import CssBaseline from '../components/css-baseline/css-baseline';
import TrafficCalendar from './components/traffic-calendar';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { useSpeedFactorTranslation } from './translations';
import { useGetServiceAreaRootUrl } from '../utils/use-get-service-area-root-url';

const TrafficMatrixPage = observer(() => {
	const { serviceAreasStore } = useStores();
	const { id: serviceAreaId } = useParams();
	const history = useHistory();
	const translations = useSpeedFactorTranslation();

	const speedFactorEnabled = useHasFeatureFlag('enable_speed_factor_for_sa');
	const featureRootUrl = useGetServiceAreaRootUrl();

	const serviceArea = useMemo(
		() => serviceAreasStore.serviceAreas.get(Number(serviceAreaId)),
		[serviceAreasStore.serviceAreas, serviceAreaId]
	);

	useEffect(() => {
		if (!serviceAreasStore.isFetched) {
			return;
		}

		if (!speedFactorEnabled || !serviceArea) history.replace(featureRootUrl);
	}, [speedFactorEnabled, serviceAreasStore.isFetched, serviceArea, featureRootUrl]);

	useEffect(() => {
		const fetchData = async () => await serviceAreasStore.fetchAll();

		fetchData();
	}, [serviceAreaId]);

	const handleBack = useCallback(
		() => history.push(`${featureRootUrl}?id=${serviceAreaId}`),
		[serviceAreaId, featureRootUrl]
	);

	if (!serviceArea) return null;

	return (
		<CssBaseline>
			<Page
				title={`${translations.pageTitle} – «${serviceArea.name}»`}
				subtitle={translations.pageSubtitle}
				rightGutter
				onBack={handleBack}
			>
				<TrafficCalendar serviceAreaId={Number(serviceAreaId)} />
			</Page>
		</CssBaseline>
	);
});

export default TrafficMatrixPage;
