import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { getRoot } from 'mobx-easy';
import {
	TemplateType,
	TemplateCreateRequest,
	TemplateUpdateRequest,
	RuleCategory,
	TextTemplatePlaceholderWithOptionalValues,
	SendTestEmailRequest,
	TemplateTypeEnum
} from '@bringg/types';
import { cloneDeep } from 'lodash';
import { useHasFeatureFlag } from '@bringg-frontend/hooks';

import BaseDomainStore from 'bringg-web/stores/core/base-domain-store';
import type RootStore from '../../../stores/root-store';

export class TemplatesStore extends BaseDomainStore<TemplateType> {
	loading = false;
	isFetched = false;
	loadingPlaceholders = false;
	placeholders: TextTemplatePlaceholderWithOptionalValues[] = [];
	shouldUsePrintOrder = useHasFeatureFlag('enable_print_order_and_conditionals');

	constructor() {
		super();

		makeObservable(this, {
			loading: observable,
			placeholders: observable.ref,
			loadingPlaceholders: observable,
			setIsLoading: action,
			setPlaceholders: action,
			setLoadingPlaceholders: action,
			isLoading: computed,
			templatePlaceholders: computed,
			notificationTemplates: computed
		});
	}

	setIsLoading = (isLoading: boolean) => {
		this.loading = isLoading;
	};

	setIsFetched = (isFetched: boolean) => {
		this.isFetched = isFetched;
	};

	get isLoading(): boolean {
		return this.loading;
	}

	fetchAll = async () => {
		if (this.isFetched) {
			return;
		}
		const { dashboardSdk } = getRootEnv();

		this.setIsLoading(true);
		try {
			const templates = await dashboardSdk.sdk.templatesApi.getAllTemplates();

			this.setBatch(templates);
			this.setIsFetched(true);

			this.fetchTemplateOwners();
		} catch (error) {
			console.error('failed to fetch templates', error);
		} finally {
			this.setIsLoading(false);
		}
	};

	fetchTemplateOwners = async () => {
		// drivers should be fetched to show all data in table
		// use batchFetch with all related to templates drivers instead of fetchAll
		// the some part of users already fetched when the Template page is opened
		// but in case fetched users for some reason doesn't includes one of the template owners
		// we need to fetch this user to show correct info in data table
		const usersToLoad = [];
		const { driversStore } = getRoot<RootStore>().data;

		this.all.forEach(({ owner_user_id }) => {
			if (!driversStore.get(owner_user_id)) {
				usersToLoad.push(owner_user_id);
			}
		});

		if (usersToLoad.length) {
			await Promise.allSettled(usersToLoad.map(async id => driversStore.fetch(id)));
		}
	};

	fetchTemplateById = async (id: number) => {
		const { dashboardSdk } = getRootEnv();

		try {
			const template = await dashboardSdk.sdk.templatesApi.getTemplateById(id);

			return template;
		} catch (error) {
			console.error('failed to fetch template by id', error);
		}
	};

	createTemplate = async (payload: TemplateCreateRequest) => {
		const { dashboardSdk } = getRootEnv();
		try {
			const createdTemplate = await dashboardSdk.sdk.templatesApi.createTemplate(payload);

			this.set(createdTemplate);
		} catch (error) {
			console.error('failed to create template', error);
			throw error;
		}
	};

	updateTemplate = async (id: number, template: TemplateUpdateRequest) => {
		const { dashboardSdk } = getRootEnv();
		try {
			const payload = cloneDeep(template);
			const initialValues = this.get(id);

			// for proper update template langs we need to use their id(if exist) in case it is new lang no id should be present
			payload.body.forEach(updateTemplateLang => {
				const initialTemplateLang = initialValues.template_languages.find(
					initLan => initLan.language_code === updateTemplateLang.language_code
				);
				if (initialTemplateLang) {
					updateTemplateLang.id = initialTemplateLang.id;
				}
			});

			const updatedTemplate = await dashboardSdk.sdk.templatesApi.updateTemplate(id, payload);

			this.set(updatedTemplate);
		} catch (error) {
			console.error('failed to update template', error);
			throw error;
		}
	};

	deleteTemplateById = async (id: number) => {
		const { dashboardSdk } = getRootEnv();
		try {
			await dashboardSdk.sdk.templatesApi.deleteTemplate(id);

			this.remove(id);
		} catch (error) {
			console.error('failed to delete template', error);
			throw error;
		}
	};

	setPlaceholders = (placeholders: TextTemplatePlaceholderWithOptionalValues[]) => {
		this.placeholders = placeholders;
	};

	get templatePlaceholders(): TextTemplatePlaceholderWithOptionalValues[] {
		return toJS(this.placeholders);
	}

	fetchPlaceholders = async () => {
		this.setLoadingPlaceholders(true);

		try {
			// TODO: this is a temporary solution and we must move to a template-service endpoint here when it is ready
			const response = await getRootEnv().dashboardSdk.sdk.rules.getMetadata({
				category_id: RuleCategory.Workflow
			});

			this.setPlaceholders(response?.actions?.sharedLocation?.placeholdersByFact?.task ?? []);
		} catch (error) {
			console.error('failed to get placeholders for template');
			throw error;
		} finally {
			this.setLoadingPlaceholders(false);
		}
	};

	setLoadingPlaceholders(value: boolean) {
		this.loadingPlaceholders = value;
	}

	getMappedToServer = (id: number) => {
		const template = this.get(id);
		const { defaultLanguageCode } = getRoot<RootStore>().data.merchantCustomerNotificationsStore;

		return {
			template_type: template.template_type,
			name: template.name,
			content_type: template.content_type,
			// TODO: temp solution while backed not returning correct response
			body: template.template_languages.reduce((acc, lang) => {
				const mappedLanguage = {
					id: lang.id,
					subject: lang.extras?.subject as string,
					content: lang.content,
					language_code: lang.language_code
				};
				if (mappedLanguage.language_code === defaultLanguageCode) {
					acc.unshift(mappedLanguage);
				} else {
					acc.push(mappedLanguage);
				}

				return acc;
			}, [])
		};
	};

	sendTestEmail = async (payload: SendTestEmailRequest) => {
		const { dashboardSdk } = getRootEnv();
		try {
			await dashboardSdk.sdk.templatesApi.sendTestEmail(payload);
		} catch (error) {
			console.error('failed to create template', error);
			throw error;
		}
	};

	get hasPrintOrderTemplate() {
		return this.all.some(({ template_type }) => TemplateTypeEnum.print_order === template_type);
	}

	get notificationTemplates() {
		// TODO: Rename TemplateTypeEnum.workflow_email https://bringg.atlassian.net/browse/BRNGG-24758
		let templateTypes = [TemplateTypeEnum.customer_notification_email];
		if (this.shouldUsePrintOrder) {
			templateTypes = [...templateTypes, TemplateTypeEnum.print_order];
		}
		return this.all.filter(({ template_type }) => templateTypes.includes(template_type));
	}
}
