import { getSharedRootStore, hasFeatureFlag } from '@bringg-frontend/global-stores';

export const useHasFeatureFlag = (featureFlag: string, team?: Bringg.Team) => {
	return hasFeatureFlag(getSharedRootStore().data.usersStore.currentUser, featureFlag, team);
};

export const useFeatureFlags = (): { [key: string]: boolean } | undefined => {
	const currentUser = getSharedRootStore().data.usersStore.currentUser;

	return currentUser?.feature_flags;
};

export const useGetFeatureFlag = (featureFlag: string) => {
	const featureFlags = useFeatureFlags();

	return featureFlags?.[featureFlag];
};
